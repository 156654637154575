import React, { useState } from 'react';
import HtmlContentComponent from './HtmlContentComponent';

const DescriptionComponent = ({ apidata }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const fullDescription = apidata ? apidata.data.report.description : '';
  const paragraphs = fullDescription.split('\n').filter(paragraph => paragraph.trim() !== '');

  const continuationText = "Read more";
  const maxChars = 150;

  const truncateText = (paragraphs, maxChars, continuationText) => {
    let truncatedText = '';
    let charCount = 0;
    
    for (let paragraph of paragraphs) {
      if (charCount + paragraph.length + continuationText.length > maxChars) {
        truncatedText += paragraph.slice(0, maxChars - charCount - continuationText.length) + '...';
        break;
      }
      truncatedText += paragraph + '\n';
      charCount += paragraph.length;
    }
    
    return truncatedText.trim();
  };

  const truncatedDescription = truncateText(paragraphs, maxChars, continuationText);

  return (
    <div className='mt-2' style={{ fontSize: '14px' }}>
      {isExpanded ? (
        <div className='mt-2'>
          {paragraphs.map((paragraph, index) => (
            <HtmlContentComponent htmlContent={paragraph} />
          ))}
          <p onClick={() => setIsExpanded(false)} style={{fontWeight:'bold'}}>Read Less</p>
        </div>
      ) : (
        <div className='mt-2'>
          {truncatedDescription.split('\n').map((paragraph, index) => (
           <div><HtmlContentComponent htmlContent={paragraph} /></div>
          ))}
          <p onClick={() => setIsExpanded(true)} style={{fontWeight:'bold'}}>Read More</p>
        </div>
      )}
    </div>
  );
};

export default DescriptionComponent;
