import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [profileData, setProfileData] = useState(null);

  // Function to log in
  const login = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      setIsAuthenticated(true);
      fetchProfile(token);
    }
  };

  // Function to log out
  const logout = () => {
    console.log("logout");
    localStorage.removeItem('token');
    setToken(null);
    setProfileData(null);
    setIsAuthenticated(false);
  };

  const fetchProfile = async (token) => {
    try {
      // setProfileData(null);
      const response = await fetch('https://admin.smacflavourfolio.com/api/user/fetchReports', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      });

      const data = await response.json();
      if (!data.errors) {
        console.log("data is ", data.data);
        setProfileData(data.data);
      } else {
        console.log("Profile not found");
      }
    } catch (error) {
      console.log("Exception error occurred", error);
    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
      setIsAuthenticated(true);
      fetchProfile(savedToken);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, fetchProfile, token, profileData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
