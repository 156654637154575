import React, { useState, useEffect } from "react";
import './onthenose.css';
import img1 from '../../assets/assest/aromatherapy.png';
import Loader from "../loader/loader.js";
import { useNavigate} from 'react-router-dom';

const SearchNose = () => {

  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const [searchData, setSearchData] =useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');

  useEffect(() => {
  async function fetchData() {
    const formData = new FormData();
    formData.append('type', localStorage.getItem('type'));
      setTimeout(() => {
        fetch('https://admin.smacflavourfolio.com/api/user/categories', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setApiData(JSON.parse(JSON.stringify(data)));
        setSearchData(JSON.parse(JSON.stringify(data))["data"]);
        setIsLoading(false);
        if(apiData["errors"])
        {
           console.log('result',apiData["errors"]);
        }
        else
        {
          console.log('result',apiData["errors"]);
          navigate("/OnTheNosePlate");
        }
      });
      }, 2000);
    }
    fetchData();
  }, []);

  const selectCategory = (id) =>
  {
    if(localStorage.getItem('isActive')=='0')
    {
    localStorage.setItem('nose_category',id);
    console.log('nose_category',id);
    }
    else
    {
      localStorage.setItem('nosePlate_category',id);
      console.log('nosePlate_category',id);
    }
    navigate('/OnTheNosePlate');
  }

  const handleInputChange = (event) => {
    const { value } = event.target;
    setQuery(value);
    if(value.length>0)
    {
    const filteredItems = apiData["data"].filter((item) =>
    item["name"].toLowerCase().includes(value.toLowerCase())
    );
    console.log('search data',filteredItems);
     setSearchData(filteredItems);
    }
    else
    {
      setSearchData(apiData["data"]); 
    }
  };

  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (<div
        className="main"
        style={{ backgroundColor: "#fff0d9", height: "100vh", width: "100vw" }}
      >
        <div className="container mt-4 cw-header">
          <div className="row">
          <div className="col-12">
            <input
             type="text"
             placeholder="Search..."
             value={query}
             onChange={handleInputChange}
             style={{width:'100%',height:'40px',borderRight:'5px',paddingLeft:'5px',border:'2px solid white'}}
            />
            </div>
            <div className='col-12 d-flex justify-content-center mt-4'>
               <b style={{fontSize:'18px'}}> ON THE NOSE</b>
            </div>
          </div>
        </div>

        <section className="container mt-1">
          <div className="row">
            {searchData.map((data) => (
                <div className="col-6 d-flex justify-content-center img-box" onClick={()=>selectCategory(data["id"])}>
                    <h4 style={{textAlign:'center'}}>
                    <img className="whiskyBottle" src={img1} alt="" /><br/><br/>
                    <p style={{fontSize:'14px'}}>{data["name"]}</p>
                    </h4>
                  </div>
            ))}
          </div>
        </section>
      </div>
      )}
    </>
  )
}

export default SearchNose