import React, { useEffect } from "react";
import profile from "../../assets/assest/profile icon.png"
import logo from "../../assets/assest/default.png";
import image1 from '../../assets/assest/image1.png';
import image2 from '../../assets/assest/image2.png';
import image3 from '../../assets/assest/image3.png';
import image4 from '../../assets/assest/image4.png';
import image5 from '../../assets/assest/image5.png';
import image6 from '../../assets/assest/image6.png';
import im1 from '../../assets/assest/im-1.png';
import im2 from '../../assets/assest/im-2.png';
import im3 from '../../assets/assest/im-3.png';
import im4 from '../../assets/assest/im-4.png'
import im5 from '../../assets/assest/im-5.png'
import im6 from '../../assets/assest/im-6.png'
import "./flavourfolioclub.css";


const FlavourFolioClub = () => {
  const images = [image1,image2,image3,image4,image5,image6,];
  const images1 = [im1,im2,im3,im4,im5,im6];
  const angle = 360 / images.length;

  
  const calculatePosition = (index) => {
    const rotation = angle * index;
    const radius = 30; 
    const x = Math.cos(rotation * Math.PI / 180) * radius;
    const y = Math.sin(rotation * Math.PI / 180) * radius;
    return { x, y };
  };

return (
    <div className=" flavour-container container-fluid h-100 position-absolute" >
       <div className=" flavour-container">
       {/* <div className="">
        <img
          src={bgimg}
          className="bg-image"
          alt="bg-img"
        />
      */}
      <div className=" w-100 d-flex justify-content-center position-relative ">
        <img src={logo} alt="img" style={{ height: "6rem",cursor:'pointer' }}
        />
      </div>
<div className="title">
    <p>FLAVOUR FOLIO PRESENTER BY SMAC</p>
</div>
<div className="flavours">
    <div className="circular-layout">
      {images.map((image, index) => {
        const position = calculatePosition(index);
        return (
          <div
            key={index}
            className="image-container"
            style={{ top: `calc(50% - 40px + ${position.y}px)`, left: `calc(50% - 40px + ${position.x}px)` }}
          >
            <img src={image} alt={`Image ${index + 1}`} className="circular-image" />
          </div>
        );
      })}
    </div>
      <div className="name-container">
        <h5>SAM KARAN</h5>
      </div>
      <div className="profile-container">
      <img src={profile} className="profile-pic" alt="profile-img" />
      </div>

</div>
<div className="flavour-images">
<div className="circular-layout">
      {images1.map((image, index) => {
        const position = calculatePosition(index);
        return (
          <div
            key={index}
            className="image-container"
            style={{ top: `calc(50% - 40px + ${position.y}px)`, left: `calc(50% - 40px + ${position.x}px)` }}
          >
            <img src={image} alt={`Image ${index + 1}`} className="circular-image" />
          </div>
        );
      })}
    </div>
    </div>
<div className="flavour-content">
  <p>AMRUT</p>
  <div className="report-content">
            <p>
        <span className="stars">★★★★☆</span> 4.0
      </p>
            </div>
  <div className="container my-2 mt-3">
          <p style={{textAlign:'justify',fontSize:'14px'}}>
            Welcome to SMAC India's Flavour Folio to capture your whisky tasting
            notes. With over 200 different flavours presented visually and a
            guided process you are unlikely to miss any notes and will capture
            notes like a pro!
          </p>
          </div>
</div>
</div>
<div className=" justify-content-center align-items-center mb-2 w-72" style={{marginTop:'30px'}} >
  
<div className="form-group align-items-center" style={{backgroundColor:'#F7F9FD',width:'383px',height:'180px',padding:'10px',borderRadius:'20px',}}>
  <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginBottom:'-15px'}}>
    <div className="on_the_nose_text" >
      <p>ON THE NOSE</p>
    </div>
    <div className="on_the_nose_rating">
      <p>
        <span className="stars">★★★★☆</span> 4.0
      </p>
    </div>
  </div>
  <hr/>
  <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginBottom:'-15px' }}>
    <div className="on_the_nose_text">
      <p>ON THE PALATE</p>
    </div>
    <div className="on_the_nose_rating">
      <p>
        <span className="stars">★★★★☆</span> 4.0
      </p>
    </div>
  </div>
  <hr/>
  <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <div className="on_the_nose_text">
      <p>FINISH</p>
    </div>
    <div className="on_the_nose_rating">
      <p>
        <span className="stars">★★★★☆</span> 4.0
      </p>
    </div>
  </div>
</div>
<button className="btn smacid-btn" style={{float:'left', width:'383px',height:'64px',backgroundColor:'#0A253C',borderRadius:'12px',marginTop:'10px'}} >
            <b style={{fontSize:'12px'}}>
              SHARE ON FB/INSTAGRAM
            </b>
            </button>
            <button className="btn smacid-btn" style={{float:'left', width:'383px',height:'64px',backgroundColor:'white',borderRadius:'12px',marginTop:'10px',color:'#0A253C',border:'2px solid #0A253C'}} >
            <b style={{fontSize:'12px'}}>
              JOIN THE CLUB
            </b>
            </button>
</div>
      
        </div>
  );
};

export default FlavourFolioClub;