import React, { useEffect, useState } from 'react';
import './profilepage.css';
import bgimg from '../../assets/assest/Background.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import backbutton from '../../assets/assest/back.jpeg';
import filter from '../../assets/assest/filter.png';

const UserProfile = () => {
  const location = useLocation();
  const { reports } = location.state || {};
  const [profileData, setProfileData] = useState(null);
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [allWhiskies, setAllWhiskies] = useState([]);
  const [selectedSection, setSelectedSection] = useState('reports');
  const [showDropdown, setShowDropdown] = useState(false);
  const token = localStorage.getItem('authToken');
  

  useEffect(() => {
    if (reports) {
      localStorage.setItem('reports', JSON.stringify(reports));
      setProfileData(reports);
      setSearchData(reports);
    } else {
      const data = JSON.parse(localStorage.getItem('reports'));
      setProfileData(data);
      setSearchData(data);
    }
  }, [reports]);

  useEffect(() => {
    if (selectedSection === 'whiskey') {
      fetchAllWhiskies();
    }
  }, [selectedSection]);

  const fetchAllWhiskies = async () => {
    try {
      const response = await fetch('https://admin.smacflavourfolio.com/api/user/whiskeys_review');
      const data = await response.json();
      if (data != null) {
        setAllWhiskies(data.data);
        setSearchData(data.data);
      }
    } catch (error) {
      console.error('Error fetching all whiskies:', error);
    }
  };

  const goToProfileDetails = (id) => {
    navigate('/whiskyclub', { state: { report_id: id } });
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  const handlePopstate = () => {
    window.history.back();
  };

  const convertRatingToStars = (rating) => {
    const totalStars = 10;
    const filledStars = Math.round(rating);
    const starsArray = Array.from({ length: totalStars }, (v, i) =>
      i < filledStars ? '★' : '☆'
    );
    return starsArray.join('');
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setQuery(value);
    if (value.length > 0) {
      const filteredItems = selectedSection === 'reports' ? profileData : allWhiskies;
      const filteredData = filteredItems.filter(
        (item) =>{
          const whiskeyName = selectedSection === 'reports' ? item.whisky.whiskey_name : item.whiskey_name;
          return whiskeyName.toLowerCase().includes(value.toLowerCase());
        }
      );
      //     item.whisky &&
      //     item.whisky.whiskey_name &&
      //     item.whisky.whiskey_name.toLowerCase().includes(value.toLowerCase())
      // );
      setSearchData(filteredData);
    } else {
      setSearchData(selectedSection === 'reports' ? profileData : allWhiskies);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleFilterByName = () => {
    const sortedByName = [...searchData].sort((a, b) => {
      const nameA = selectedSection === 'reports' ? a.whisky.whiskey_name : a.whiskey_name;
      const nameB = selectedSection === 'reports' ? b.whisky.whiskey_name : b.whiskey_name;
      return nameA.localeCompare(nameB);
    });
    setSearchData(sortedByName);
    setShowDropdown(false);
  };


  const handleFilterByDate = () => {
    const sortedByDate = [...searchData].sort((a, b) =>
      new Date(b.created_at) - new Date(a.created_at)
    );
    setSearchData(sortedByDate);
    setShowDropdown(false);
  };

  const toggleSection = (section) => {
    setSelectedSection(section);
    setQuery('');
    if (section === 'reports') {
      setSearchData(profileData);
    } else {
      setSearchData(allWhiskies);
    }
  };

  const handleClick = (id) => {
    navigate('/overallreview', { state: { whisky_id: id } });
    // console.log("profilepage click",id);
  };

  return (
    <div className="container">
      <div className='row'>
      <div className="position-absolute mt-2 mb-5">
          <button
            type="button"
            className="btn-link"
            style={{
              color: 'white',
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              
            }}
            onClick={handlePopstate}
          >
            <div className='gradient-border'>
              <img src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px',borderRadius:'13px' }} alt="Back" />
              </div>
          </button>
        </div>
      </div>

      <div className='row'> 
      <div className="user-profile">
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${selectedSection === 'reports' ? 'active' : ''}`}
            onClick={() => toggleSection('reports')}
          >
            My Reviews
          </button>
          <button
            className={`toggle-button ${selectedSection === 'whiskey' ? 'active' : ''}`}
            onClick={() => toggleSection('whiskey')}
          >
            All Reviews
          </button>
        </div>
        {/* <h3 className="report-heading">{selectedSection === 'whiskey' ? 'Overall Review' : 'MyReview'}</h3> */}

        <div className="container cw-header" style={{ paddingLeft: '5%', paddingRight: '5%', position: 'relative' }}>
          <div className="row">
            <div className="col-12">
              <input
                type="text"
                className="design_search"
                placeholder="Search ..."
                value={query}
                onChange={handleInputChange}
                style={{
                  width: '100%',
                  height: '40px',
                  paddingRight: '30px',
                  paddingLeft: '5px',
                  marginBottom: '10px',
                  marginTop: '20px',
                }}
              />
              {selectedSection === 'reports' && (
                <img
                  src={filter}
                  onClick={toggleDropdown}
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'absolute',
                    right: '28px',
                    top: '30px',
                    cursor: 'pointer',
                  }}
                  alt="Filter"
                />
              )}
              {showDropdown && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50px',
                    right: '10px',
                    backgroundColor: 'white',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '4px',
                    zIndex: 1000,
                  }}
                >
                  <div
                    onClick={handleFilterByName}
                    style={{
                      padding: '10px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #ccc',
                    }}
                  >
                    Filter by name
                  </div>
                  <div
                    onClick={handleFilterByDate}
                    style={{
                      padding: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    Filter by rated on date
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {selectedSection === 'reports' && (
  <div>
    {searchData && searchData.length > 0 ? (
      searchData.map((rep) => (
        <div
          className="reports"
          key={rep.id}
          onClick={() => goToProfileDetails(rep.id)}
        >
          <div className="image">
            <img src={rep.whisky.icon.url} alt="Image" />
          </div>
          <div className="report-content" style={{ paddingLeft: '10px', width: '100%' }}>
            <span>
              <h6 style={{ marginBottom: '3px' }}>{rep.whisky.whiskey_name}</h6>
              <span className="stars">
                {convertRatingToStars(rep.ratings)}
              </span>
              <span style={{ color: 'black', fontSize: '12px' }}>
                {rep.ratings}.0
              </span>
              <p style={{ color: '#B1B1B1' }}>Rated On: {formatDate(rep.created_at)}</p>
            </span>
          </div>
        </div>
      ))
    ) : (
      <div class="text-center">No reviews found! Rate one now</div>
    )}
  </div>
)}

{selectedSection === 'whiskey' && (
  <div>
    {searchData && searchData.length > 0 ? (
      searchData.map((whiskey) => (
        <div
          className="reports1"
          key={whiskey.id}
          onClick={() => handleClick(whiskey.id)}
          style={{
            textAlign: 'left',
            paddingLeft: '20px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #ddd',
            paddingBottom: '10px',
            marginBottom: '10px'
          }}
        >
          <div>
            <img
              className="whiskyBottle"
              src={whiskey.icon.url}
              alt=""
              style={{
                width: '79px',
                height: '59px',
                marginRight: '10px'
              }}
            />
          </div>
          <div>
            <p className="truncate" style={{ margin: 0 }}>
              <b>{whiskey.whiskey_name}</b>
            </p>
          </div>
        </div>
      ))
    ) : (
      <div class="text-center">No reports found!</div>
    )}
  </div>
)}

      </div>
    </div>
    </div>
  );
};

export default UserProfile;

