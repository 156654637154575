// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Glacial+Indifference&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glacial-text {
  font-family: 'Glacial Indifference', sans-serif;
}

.flavour-folio-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.overlay {
  position: absolute;
  bottom: 0px; 
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.logo {
  width: 80%; /* Adjust as needed */
}

.start-button {
  width: 100%;
  background-color: rgb(10, 37, 60);
  border-radius: 15px;
  color: white;
  transition: transform .5s;
  font-size: 22px;
  box-shadow: 0px 2px 4px rgb(0, 0, 0);
}

.start-button:hover {
  background-color: #0a253c; /* Adjust as needed */
}`, "",{"version":3,"sources":["webpack://./src/pages/DashBoard/dashboard.css"],"names":[],"mappings":"AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,UAAU,EAAE,qBAAqB;AACnC;;AAEA;EACE,WAAW;EACX,iCAAiC;EACjC,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,yBAAyB,EAAE,qBAAqB;AAClD","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Glacial+Indifference&display=swap');\n\n.glacial-text {\n  font-family: 'Glacial Indifference', sans-serif;\n}\n\n.flavour-folio-container {\n  position: relative;\n  width: 100%;\n  height: 100vh;\n  overflow: hidden;\n}\n\n.background-image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: -1;\n}\n\n.overlay {\n  position: absolute;\n  bottom: 0px; \n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n}\n\n.logo {\n  width: 80%; /* Adjust as needed */\n}\n\n.start-button {\n  width: 100%;\n  background-color: rgb(10, 37, 60);\n  border-radius: 15px;\n  color: white;\n  transition: transform .5s;\n  font-size: 22px;\n  box-shadow: 0px 2px 4px rgb(0, 0, 0);\n}\n\n.start-button:hover {\n  background-color: #0a253c; /* Adjust as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
