import React, { useEffect } from "react";
import "./Mainpage/mainpage.css";
import whisky from "../assets/assest/Background.png";
import bottle from "../assets/assest/default-png.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./Services/AuthContext";

const ThanksPage = () => {
  const {logout} =useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    // This code will execute when the component mounts or updates
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
 
  const logoutred=()=>{
    logout();
    navigate('/smacid');
  };

  return (
    <>
      <div className="dashboardn-background" style={{ height: "100vh" }}>
      <div className="">
        <img
          src={whisky}
          className="smac_img"
          alt="bg-img"
        />
         <img src={bottle} alt="" style={{height:'23vh',position:'absolute',top:'7vh',right:'6vw'}}/>
      </div>
        <div className="container mt-5 text-center">
        <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center" style={{background:'#C07F1E',height:'100px',width:'100px',borderRadius:'100%',textAlign:'center',alignItems:'center'}}>
                  <i class="fa-solid fa-check" style={{fontSize:'45px',color:"white"}}></i>
        </div>
        </div>
        <br/>
          <h6 className="d-flex justify-content-center align-items-center">
           <strong>CONGRATULATIONS!</strong>
          </h6>
          <p className="d-flex justify-content-center align-items-center">
          Thanks For Submitting Your Review.
          </p>
          <div class="container">
            <div class="row">
              <div class="col-12"><button class="btn"  onClick={() => navigate("/choosewhisky")} style={{backgroundColor:'#0A253C',fontSize:'9',width:'100%'}}>
            <strong>RATE ANOTHER WHISKY</strong>
            {/* <i class="fa-solid fa-check ms-2"></i> */}
          </button>
          <br/><br/>
          </div>
          <div class="col-12"><button class="btn "  onClick={logoutred} style={{backgroundColor:'red'}}>
            <strong>LOGOUT</strong>
            {/* <i class="fa-solid fa-check ms-2"></i> */}
          </button>
          </div>
            </div>
          </div>
        </div>
        <br/>
      </div>
    </>
  );
};

export default ThanksPage;