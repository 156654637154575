// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whisky-name{
    color: black;
    font-size: 16px;
    padding-left:10px;
   
}
.aromas{
    margin: 18px;
    background-color: #F7F9FD;
    border-radius: 15px;
    padding: 13px;
    margin-bottom: 27px;
  }
  /* .aromas-img::-webkit-scrollbar {
    display: none;
  } */
  
  .aromas-img {
    display: flex;
    flex-wrap: wrap; 
    /* justify-content: space-between;  */
    width: 100%;
    align-items: flex-start;
  }
  
  .image {
    background-color: white;
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(25% - 10px); 
    margin: 4px; 
  }
  
  .image img {
    width: 100%;
    height: auto;
    padding: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Overallreview/overallreview.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;;AAErB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;EACrB;EACA;;KAEG;;EAEH;IACE,aAAa;IACb,eAAe;IACf,qCAAqC;IACrC,WAAW;IACX,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B;IAC1B,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,YAAY;EACd","sourcesContent":[".whisky-name{\n    color: black;\n    font-size: 16px;\n    padding-left:10px;\n   \n}\n.aromas{\n    margin: 18px;\n    background-color: #F7F9FD;\n    border-radius: 15px;\n    padding: 13px;\n    margin-bottom: 27px;\n  }\n  /* .aromas-img::-webkit-scrollbar {\n    display: none;\n  } */\n  \n  .aromas-img {\n    display: flex;\n    flex-wrap: wrap; \n    /* justify-content: space-between;  */\n    width: 100%;\n    align-items: flex-start;\n  }\n  \n  .image {\n    background-color: white;\n    border-radius: 10%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    flex: 0 0 calc(25% - 10px); \n    margin: 4px; \n  }\n  \n  .image img {\n    width: 100%;\n    height: auto;\n    padding: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
