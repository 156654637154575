import React, { useState, useEffect } from "react";
import './rating.css';
// import nose from '../assets/assest/feedback.png';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Loader from "./loader/loader.js";
import Modal from 'react-modal';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import Filter from 'bad-words';
import backbutton from '../../src/assets/assest/back.jpeg'
import nose from '../assets/assest/finish_image.png';
// import styled from 'styled-components';
import uploadedImage from "../../src/assets/assest/upload_image.png";
import imageCompression from "browser-image-compression";

const Submit = () => {
  const location = useLocation();
  const { report_id } = location.state || {};
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [rating,setRating]=useState(0);
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [onChangeText, setChangeText] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('Size Limit:10MB');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  const [note, setNote] = useState('');

  const handleInputChange = (event) => {
    setNote(event.target.value);
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError(false);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  
  const customStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-48%, -50%)',
      border: 'none',
      borderRadius: '10px',
      padding: '20px',
      maxWidth: '350px',
      width: '74%',
      backgroundColor: '#f9f9f9',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    }
  };

  const handleImageUpload = async (imageFile) => {
    // Options for compression
    const options = {
      maxSizeMB: 2, // (2 MB)
      maxWidthOrHeight: 1024, // (You can set a maximum width/height)
      useWebWorker: true, // optional, defaults to true
    };

    try {
      const compressedImage = await imageCompression(imageFile, options);
      setFile(compressedImage);
      checkfile(compressedImage);
      
      // You can now handle the compressed image
      console.log("Original File Size:", imageFile.size / 1024, "KB");
      console.log("Compressed File Size:", compressedImage);

      // // Example: create URL for the compressed image for preview or upload
      // const compressedImageUrl = URL.createObjectURL(compressedImage);
      // console.log("Compressed Image URL:", compressedImageUrl);
    } catch (error) {
      console.error("Error during image compression:", error);
    }
  };

  function handleFileChange(event){
    const file = event.target.files[0];

    if (file) {
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

      if (fileSizeInBytes <= maxSizeInBytes) {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = getFileExtension(file.name);
        if (allowedExtensions.includes(fileExtension.toLowerCase())) 
          { 
          // setFile(file);
          // checkfile(file);
          handleImageUpload(file);
          }
        else
          {
            setFile(null);
            setPreviewImage(null);
            setErrorMessage('Invalid file extension. Only JPG, JPEG, and PNG files are allowed.');
          }
      } else {
        setFile(null);
        setPreviewImage(null);
        setErrorMessage('File size exceeds the maximum limit (10MB).');
      }
    } else {
      setFile(null);
      setPreviewImage(null);
      setErrorMessage('Size Limit:10MB');
    }
  }

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const selectedRating=(number)=>{
    console.log('number',number);
     let errors = {};
    if (number>=1) {
      errors.rating = '';
      setFormErrors(errors);
    }
    setRating(number);
  };

  function handleTextChange(event) {
    const filter = new Filter();
    const value = event.target.value;
    
    // Check for foul language
    if (filter.isProfane(value)) {
      setText(filter.clean(value));
      setErrorMessages('Foul language is not allowed!');
    } else {
      setText(event.target.value);
      setErrorMessages('');
    }
  }

  const clickHandle=()=>
  {
    if(onChangeText==true)
    {
      setText('');
      setChangeText(false);
    }
    console.log(onChangeText);
  }

  // const submitRating=()=>{
  //   localStorage.setItem('ratings',rating);
  //   localStorage.setItem('review',text);
  //   handleSubmit();
  // }

  const checkfile=(file)=> {
    const formData = new FormData();
    formData.append('file', file);
    fetch('https://admin.smacflavourfolio.com/api/user/checkImages', {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
      .then(data => {
      // Handle the response
      if(data["errors"]==false)
        {
          setFile(file);
          const reader = new FileReader();
          reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
          setErrorMessage('');
        }
        else
        {
          setFile(null);
          setPreviewImage(null);
          setErrorMessage(data["message"]);
        }
    })
    .catch(error => {
      // Handle the error
    });
  }


  const handleSubmit=async()=> {
    if(captchaValue)
      {
    setIsLoading(true);
    localStorage.setItem('ratings',rating);
    localStorage.setItem('review',text);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('smacid',localStorage.getItem('smacid'));
    formData.append('whiskey_id',localStorage.getItem('whiskey_id'));
    formData.append('on_the_nose',localStorage.getItem('on_the_nose'));
    formData.append('on_the_palate',localStorage.getItem('on_the_palate'));
    formData.append('on_the_nose_rating',localStorage.getItem('on_the_nose_rating')=="null"?'0':localStorage.getItem('on_the_nose_rating'));
    formData.append('on_the_palate_ratings',localStorage.getItem('on_the_palate_ratings')=="null"?'0':localStorage.getItem('on_the_palate_ratings'));
    formData.append('ratings',localStorage.getItem('ratings'));
    formData.append('review',note);
    formData.append('rating',localStorage.getItem('rating'));
    formData.append('reviee',localStorage.getItem('reviee'));

    console.log('smacid',localStorage.getItem('smacid'));
    console.log('whiskey_id',localStorage.getItem('whiskey_id'));
    console.log('on_the_nose',localStorage.getItem('on_the_nose'));
    console.log('on_the_palate',localStorage.getItem('on_the_palate'));
    console.log('on_the_nose_rating',localStorage.getItem('on_the_nose_rating'));
    console.log('on_the_palate_ratings',localStorage.getItem('on_the_palate_ratings'));
    console.log('ratings',localStorage.getItem('ratings'));
    console.log('review',note);
    console.log('rating',localStorage.getItem('rating'));
    // console.log('reviee',localStorage.getItem('reviee'));
    
    const token=localStorage.getItem('token');
    const response=await fetch('https://admin.smacflavourfolio.com/api/user/feedback', {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer '+token
      }
    });
      const data=await response.json();
      console.log("submitApiResponse",data)
      setIsLoading(false);
      localStorage.setItem('whiskey_id',null);
      localStorage.setItem('on_the_nose',null);
      localStorage.setItem('on_the_palate',null);
      localStorage.setItem('on_the_nose_rating',null);
      localStorage.setItem('on_the_palate_ratings',null);
      localStorage.setItem('ratings',null);
      localStorage.setItem('review',null);
      localStorage.setItem('rating',null);
      localStorage.setItem('reviee',null);
      setCaptchaValue(null);
      setCaptchaError(false);
      navigate("/whisky",{state:{report_id:data.data.id,uploadedImage: previewImage }});
  }
  else
  {
    setCaptchaError(true);
  }
  }


  const [formErrors, setFormErrors] = useState({
    rating: '',
  });

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate name field
    if (rating<1) {
      isValid = false;
      errors.rating = 'Rating is required';
      setFormErrors(errors);
    }
    else
    {
      handleOpenModal();
    }

    //Validate email field
  };

  const getFileExtension = (fileName) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  };

  const handleGoBack = () => {
    window.history.back();
  };


//   const TextArea = styled.textarea`
//   color: ${props => props.isPlaceholder ? '#999999' : 'inherit'};
// `;

useEffect(() => {
  // This code will execute when the component mounts or updates
  
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

  // const handleInputChange = (e) => {
  //   setText(e.target.value);
  // };

  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (<div className="dashboardn-background p-2">
      <div>
      <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer',marginTop:'1px',marginLeft:'-5px' }}
                 onClick={()=>handleGoBack()}
                >
                <div className='gradient-border'>
                  <img className="gradient-border-img" src={backbutton} style={{cursor:'pointer',width:'50px',height:'50px',borderRadius:'13px'}}/>
                </div>
              </button>
            </div>
        {/* <div>
        <b style={{textDecoration: 'underline',fontSize:'14px',cursor:'pointer'}} onClick={()=>handleGoBack()}>  GO TO MAIN SCREEN </b>
        </div> */}
        {/* <div>
          <img className="mt-5 mx-3" alt="" src={nose} style={{width:'100px'}}/>

        </div> */}
        <div className="content-container1">
        <h1 className="title1">WHISKY VERDICT</h1>
        <img  alt="nose" src={nose} style={{width:'150px'}}/>
        </div>
        <div>
        <div className="outerbox1 p-2 mb-3  mt-2 justify-content-center">
        <textarea
          value={note}
          onChange={handleInputChange}
          placeholder="Feel free to enter your own notes or any flavours that might not be listed. While not mandatory, your input will help our AI create more personalised and nuanced tasting notes just for you!"
          rows={5}
          className="custom-textarea"
        />
        </div>

    </div>
    
  {previewImage != null ? (
  <img src={previewImage} alt="Preview" height={"300"} width={"100%"} style={{marginBottom: '20px'}}/>
) : (
  <div className="content-container1 text-center items-center ml-22">
    <div className="row justify-content-center" style={{width:'60%'}}>
      <input id="file-upload" type="file" onChange={handleFileChange} style={{display: 'none'}}/>
      <label htmlFor="file-upload">
        <div className="col-12 col-lg-12 col-md-12 col-sm-10" style={{width: '100%'}}>
          <div className="outerbox1 p-2 mb-3 justify-content-center">
            <img src={uploadedImage} className="upload_icon" alt="Upload icon"/><br/>
            {/* <i className="col-12 fa-solid fa-file-arrow-up uploadimg"></i>
            <h5>Share a picture with us</h5> */}
            <b>SNAP & SIP</b>
            <p style={{color:'red'}}>{errorMessage}</p>
          </div>
        </div>
      </label>
    </div>
  </div>
)}

        {/* <p>Any aromas that you wish to capture apart from the above?</p> */} 
        {localStorage.getItem('isActive')=="0"?(
           <h1 className="title1">OVERALL RATING</h1>
        ):
        // <p>Overall Rating: Score the whisky based on balance and flavor harmony</p>
        <h1 className="title1">OVERALL RATING</h1>
        }
        <div className='d-flex justify-content-center'>

        {numbers.map((number) => (
        <div class="text-center" style={{paddingRight:'5px'}}>
        <FontAwesomeIcon icon={faStar} className={`fas fa-star ${rating>=number ? 'myselected' : ''}`} style={{fontSize:'22px'}}  onClick={()=>selectedRating(number)}/>
        <br/>
        <label>{number}</label>
        </div>
        ))}

        </div>
        <span style={{color:'red'}}>{formErrors.rating}</span>
        <br/>
        {/* <p>WRITE A REVIEW</p> */}
        {/* <div className="form-floating input-shadow mb-3"> 
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Add any aromas or flavours that were not available on Flavour Folio"
                  id="floatingTextarea2"
                  style={{ height: "100px",color:'grey' }}
                  onClick={clickHandle}
                  value={text} onChange={handleTextChange} 
                />
                {errorMessages && <p style={{ color: 'red' }}>{errorMessages}</p>} 
                <label for="floatingTextarea2"></label>
        </div> */}


          <div className="captcha">
               <ReCAPTCHA
                  sitekey="6LeHw9spAAAAAEeAEVgYOhY8TH6m9CK2E_t1aj64"
                  onChange={onCaptchaChange}
                />
               {captchaError && (
                 <div style={{ color: 'red', marginTop: '5px' }}>
                  Please complete the CAPTCHA
                </div>
               )}
            </div>
            <div className='mybtn'>
        <button class="btn"  onClick={() => validateForm()} style={{backgroundColor:'#0A253C'}}>
            <strong>SUBMIT</strong>
        </button>
        </div>
        {/* <div className='d-flex' style={{float:'right',paddingTop:'15px',marginBottom:'30px'}}>
        <div className='mybtn'>
        <button class="btn "  onClick={() => validateForm()} style={{backgroundColor:'#0A253C'}}>
            <strong>SUBMIT</strong>
          </button>
        </div>
        </div> */}
      </div>
    </div>
    )}
    {isLoading ? (
        <div/>
      ) : (<Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        {localStorage.getItem('is_on_the_palate')==0 ||
         localStorage.getItem('is_on_the_nose')==0 ||
         localStorage.getItem('finish')==0
         ?(<p className="font">Would you want to submit you review without completing
          &nbsp;{localStorage.getItem('is_on_the_nose')==0?'AROMATICS,':''}
          &nbsp;{localStorage.getItem('is_on_the_palate')==0?'ON THE PALATE,':''}
          &nbsp;{localStorage.getItem('finish')==0?'FINISH':''}</p>)
        :(
        // <p>Please submit you feedback by clicking submit button...</p>
        <p className="font">Click 'Submit' to unleash your personalized AI tasting notes! If you want to escape this flavor adventure, just click outside. 🌈🍹</p>
        )}
        <button style={{float:'right',backgroundColor:'#0A253C'}} className="btn btn-success" onClick={()=>handleSubmit()}>Submit</button>
        {localStorage.getItem('is_on_the_palate')==0 ||
         localStorage.getItem('is_on_the_nose')==0 ||
         localStorage.getItem('finish')==0
         ?(<button style={{float:'left',backgroundColor:'#0A253C'}} className="btn btn-primary" onClick={()=>{navigate('/mainpage')}} >Go back</button>
         ):
         (<div></div>)}
      </Modal>
      )}
    </>
  );
};

export default Submit