import React, { useState, useEffect } from "react";
import './ChooseFile.css'
import img from '../../assets/assest/flags.png'
import { useNavigate } from "react-router-dom";
import Loader from "../loader/loader.js";

const ChooseFile = () => {
  
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('Size Limit:10MB');

  useEffect(() => {
    // This code will execute when the component mounts or updates
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  function handleFileChange(event) {
    const file = event.target.files[0];

    if (file) {
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

      if (fileSizeInBytes <= maxSizeInBytes) {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const fileExtension = getFileExtension(file.name);
        if (allowedExtensions.includes(fileExtension.toLowerCase())) 
          { 
          setFile(file);
          const reader = new FileReader();
          reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
          setErrorMessage('');

          }
        else
          {
            setFile(null);
            setPreviewImage(null);
            setErrorMessage('Invalid file extension. Only JPG, JPEG, and PNG files are allowed.');
          }
      } else {
        setFile(null);
        setPreviewImage(null);
        setErrorMessage('File size exceeds the maximum limit (10MB).');
      }
    } else {
      setFile(null);
      setPreviewImage(null);
      setErrorMessage('Size Limit:10MB');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('smacid',localStorage.getItem('smacid'));
    formData.append('whiskey_id',localStorage.getItem('whiskey_id'));
    formData.append('on_the_nose',localStorage.getItem('on_the_nose'));
    formData.append('on_the_palate',localStorage.getItem('on_the_palate'));
    formData.append('on_the_nose_rating',localStorage.getItem('on_the_nose_rating'));
    formData.append('on_the_palate_ratings',localStorage.getItem('on_the_palate_ratings'));
    formData.append('ratings',localStorage.getItem('ratings'));
    formData.append('review',localStorage.getItem('review'));
    formData.append('rating',localStorage.getItem('rating'));
    formData.append('reviee',localStorage.getItem('reviee'));

    console.log('smacid',localStorage.getItem('smacid'));
    console.log('whiskey_id',localStorage.getItem('whiskey_id'));
    console.log('on_the_nose',localStorage.getItem('on_the_nose'));
    console.log('on_the_palate',localStorage.getItem('on_the_palate'));
    console.log('on_the_nose_rating',localStorage.getItem('on_the_nose_rating'));
    console.log('on_the_palate_ratings',localStorage.getItem('on_the_palate_ratings'));
    console.log('ratings',localStorage.getItem('ratings'));
    console.log('review',localStorage.getItem('review'));
    console.log('rating',localStorage.getItem('rating'));
    console.log('reviee',localStorage.getItem('reviee'));
    fetch('https://admin.smacflavourfolio.com/api/user/feedback', {
      method: 'POST',
      body: formData
    })
    .then(response => {
      // Handle the response
      console.log('submit_response',JSON.parse(JSON.stringify(response)));
      setIsLoading(false);
      localStorage.setItem('whiskey_id',null);
      localStorage.setItem('on_the_nose',null);
      localStorage.setItem('on_the_palate',null);
      localStorage.setItem('on_the_nose_rating',null);
      localStorage.setItem('on_the_palate_ratings',null);
      localStorage.setItem('ratings',null);
      localStorage.setItem('review',null);
      localStorage.setItem('rating',null);
      localStorage.setItem('reviee',null);

      navigate("/thanksPage");
    })
    .catch(error => {
      // Handle the error
    });
  }

  const getFileExtension = (fileName) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  };



  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
      <div style={{ background: "#FFFFFF" }}>
      <img className="mt-5 mx-3" src={img} alt="flag"  style={{width:'100px'}}/>
      <div class="bg p-4">
        <h5><b>What to share a picture of your lasting with us ?</b></h5>
        <p>A picture is worth 1000 words. go ahead and share a selfie with the bottle</p>
        <form onSubmit={handleSubmit}>
        {previewImage!=null ? (
        <img src={previewImage} alt="Preview" height={"300"} width={"100%"} style={{marginBottom:'20px'}}/>
      
        ) : (
        <div class="container text-center">
          <div class="row">
          <input id="file-upload" type="file" onChange={handleFileChange}/>
          <label for="file-upload">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="outerbox p-2 mb-3 row d-flex justify-content-center ">
                <i class="col-12 fa-solid fa-file-arrow-up uploadimg"></i>
                <h5>CHOOSE A FILE</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
            </label>
          </div>
        </div>
        )}
        <div class="mb-3 okcheck">
          <button class="btn" type="submit">
            <strong>Ok</strong>
            <i class="fa-solid fa-check ms-2"></i>
          </button>
        </div>
        </form>
      </div>
      </div>
      )}
    </>
  );
};

export default ChooseFile;
