// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.circular-layout1{
    position: relative;
    width: 75px;
    height: 0px;
    margin-left: -80px;
    margin-top: 44px;
}
.image-container img {
    border: 1px solid black;    
}
/* .image-container img {
    width: 35px !important;
    height: 6vh !important;
} */
.profile-pic1 {
    border-radius: 50%;
    position: relative;
    bottom: 0vh;
    height: 80px;
    width: 80px;
    margin-right: 21px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/club/club.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;AACA;;;GAGG;AACH;IACI,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["\n.circular-layout1{\n    position: relative;\n    width: 75px;\n    height: 0px;\n    margin-left: -80px;\n    margin-top: 44px;\n}\n.image-container img {\n    border: 1px solid black;    \n}\n/* .image-container img {\n    width: 35px !important;\n    height: 6vh !important;\n} */\n.profile-pic1 {\n    border-radius: 50%;\n    position: relative;\n    bottom: 0vh;\n    height: 80px;\n    width: 80px;\n    margin-right: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
