// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* @font-face {
    font-family: 'MyCustomFont';
    src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');
  } */

/* .bg{
    background-color: #fff0d9;
} */

.bbg{
    background-color: #ffee83;
}

.box{
    height: 30px;
    width: 30px;
   background-color: #ffee83;
    border-radius: 10px;
    color: white;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    
}

.border{
    background-color: #ffeea3;
}


.btn {
    width: 90px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    padding: 6px;
}
input[type="file"] {
    display: none;
  }
   
.okcheck{
    float: right;
    /* background-color:#c07f1e ; */
    border-radius: 10px;
}

.outerbox{
    border-radius: 10px;
    border: 1px solid black;
    background-color: #ffffff;
}

.uploadimg{
    padding: 50px 50px 14px;
    font-size: 80px;
}


`, "",{"version":3,"sources":["webpack://./src/pages/ChooseFile/ChooseFile.css"],"names":[],"mappings":";;AAEA;;;KAGK;;AAEL;;GAEG;;AAEH;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;GACZ,yBAAyB;IACxB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,uBAAuB;;AAE3B;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,YAAY;AAChB;AACA;IACI,aAAa;EACf;;AAEF;IACI,YAAY;IACZ,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":["\n\n/* @font-face {\n    font-family: 'MyCustomFont';\n    src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');\n  } */\n\n/* .bg{\n    background-color: #fff0d9;\n} */\n\n.bbg{\n    background-color: #ffee83;\n}\n\n.box{\n    height: 30px;\n    width: 30px;\n   background-color: #ffee83;\n    border-radius: 10px;\n    color: white;\n    font-size: 10px;\n    align-items: center;\n    justify-content: center;\n    \n}\n\n.border{\n    background-color: #ffeea3;\n}\n\n\n.btn {\n    width: 90px;\n    border-radius: 5px;\n    color: white;\n    font-size: 14px;\n    padding: 6px;\n}\ninput[type=\"file\"] {\n    display: none;\n  }\n   \n.okcheck{\n    float: right;\n    /* background-color:#c07f1e ; */\n    border-radius: 10px;\n}\n\n.outerbox{\n    border-radius: 10px;\n    border: 1px solid black;\n    background-color: #ffffff;\n}\n\n.uploadimg{\n    padding: 50px 50px 14px;\n    font-size: 80px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
