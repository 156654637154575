import React, { useEffect , useState} from "react";
import profile from "../../assets/assest/profile icon.png"
import nose from "../../assets/assest/nose.png";
import palate from "../../assets/assest/palate.png";
import finish from "../../assets/assest/finish.png"
import logo from "../../assets/assest/default.png";
import image1 from '../../assets/assest/image1.png';
import image2 from '../../assets/assest/image2.png';
import image3 from '../../assets/assest/image3.png';
import image4 from '../../assets/assest/image4.png';
import image5 from '../../assets/assest/image5.png';
import image6 from '../../assets/assest/image6.png';
import im1 from '../../assets/assest/im-1.png';
import im2 from '../../assets/assest/im-2.png';
import im3 from '../../assets/assest/im-3.png';
import im4 from '../../assets/assest/im-4.png'
import im5 from '../../assets/assest/im-5.png'
import im6 from '../../assets/assest/im-6.png'
import ardmore from '../../assets/assest/ardmore.jpeg';
import fb from '../../assets/assest/fb.png';
import insta from '../../assets/assest/insta.png'
import "./club1.css";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loader from "../loader/loader.js";
import backbutton from '../../assets/assest/back.jpeg';

const Club1 = () => {
  const images = [image1,image2,image3,image4,image5,image6,nose];
  const images1 = [im1,im2,im3,im4,im5,im6,palate];
  const [apidata,setApiData]= useState(null);
  const [isLoading, setIsLoading]= useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { report_id } = location.state || {};
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    async function fetchData()
    {
      try {
        const formData = new FormData();
        formData.append('report_id', report_id);
        const response = await fetch('https://admin.smacflavourfolio.com/api/user/reportDetail',{
        method:"POST",
        body:formData,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
      });
        const data = await response.json();
        if(data!=null)
        {
        console.log("data ",data);
        setApiData(data);
      //   const mdata=apiData.data[0];
      //   localStorage.setItem('whisky', JSON.stringify(mdata));
        setIsLoading(false);
         //console.log('data',apiData["data"]);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
    fetchData();
}, []);
const goToClub = () => {
  navigate("/club",{state:{report_id:report_id}});
};
const handlePopstate = () => {
  window.history.back();
};

const goToClub2 = () => {
  navigate("/club2",{state:{report_id:report_id}});
};
const convertRatingToStar = (rating) => {
  let stars = '';
  for (let i = 0; i < rating; i++) {
    stars += '★';
  }
  return stars;
};
const convertRatingToStars = (rating) => {
  const totalStars = 10;
  const filledStars = Math.round(rating);
  const starsArray = Array.from({ length: totalStars }, (v, i) => i < filledStars ? '★' : '☆');
  return starsArray.join('');
};

const fullDescription = apidata ?(apidata.data.report.description) : '';
      const continuationText = "Read more";
      const ReadLess = "Read Less"
      const words = fullDescription.split(' ');
      let maxWords = 30;
      let visibleWords = words.slice(0, maxWords).join(' ');
    
      while (visibleWords.length + continuationText.length > 150 && maxWords > 0) {
        maxWords -= 1;
        visibleWords = words.slice(0, maxWords).join(' ');
      }
    
      // Function to toggle showing full description
      const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
      };
return (
  <div>
  {isLoading ? (
    <Loader />
  ) :
    <div className=" flavour-container container-fluid  position-absolute" style={{ backgroundColor:'#F7F9FD',fontFamily:'Lato,sans-serif'}} >
       <div className=" flavour-container">
       <div>
          <button
            type="button"
            className="btn-link"
            style={{
              color: 'white',
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
            }}
            onClick={handlePopstate}
          >
            <img src={backbutton} style={{ cursor: 'pointer', width: '35px', height: '35px' }} alt="Back" />
          </button>
        </div>
       {/* <button
        type="button"
        className="btn-link"
        style={{ color: "black", border: 'none', background: 'transparent', cursor: 'pointer',marginTop:'20px' }}
        onClick={() => goToClub()}
      >
        <i class="fa-solid fa-arrow-left" style={{ cursor: 'pointer' }}></i>
       
      </button> */}
      <div className=" w-100 d-flex justify-content-center" style={{marginLeft:'10px'}}>
      <img src={apidata.data.report.user.profile.url}  className="profile-pic" style={{bottom:'0px',border:'2px solid black'}} alt="profile-img" />
      </div>
      <div className="name text-center">
      <b>{apidata.data.report.user.name}</b>
      </div>
     
      <div className="flavours">
      <div className="circular-layout">
      <div className="im-container" style={{display:'flex',alignItems:'center'}}>
      <div className="nose-container">
        <img src={images[6]} alt="Nose Image" className="large-image" />
      </div>
      <div className="row-container" style={{ display: 'flex',  }}>
  <div className="im-container1">
    {apidata && (
      <div className="image-grid">
        {apidata.data.onthenose.slice(0,6).map((aroma, index) => (
          <div className="small-image-container" key={index}>
            <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-image" />
          </div>
        ))}
        {apidata.data.onthenose.length < 6 && apidata.data.randomonthenose.slice(0, Math.max(0, 6 - apidata.data.onthenose.length)).map((aroma, index) => (
          <div className="small-image-container" key={index + apidata.data.onthenose.length}>
            <img src={aroma.icon.url} alt={`On the Nose Image ${index + apidata.data.onthenose.length + 1}`} className="small-image" />
          </div>
        ))}
      </div>
    )}
  </div>
</div>

      {/* <div className="row-container" style={{ display:'flex',flexDirection:'column'}}>
        <div className="im-container1">
        {apidata && [...apidata.data.onthenose, ...apidata.data.randomonthenose.slice(0, 1)].map((aroma, index) => (
                  <div className="small-image-container" key={index}>
                  <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-image" />
                  </div>
              ))}
              </div>
              <div className="im-container1">
        {apidata && [ ...apidata.data.randomonthenose.slice(2, 5)].map((aroma, index) => (
                  <div className="small-image-container" key={index}>
                  <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-image" />
                  </div>
              ))}
         
        </div>
        
      </div> */}
    </div>
      </div>
      {/* <div className="ardmore-section" style={{backgroundColor:'white',width:'100%',marginTop:'20px',textAlign:'center',padding:'20px',borderRadius:'20px'}}>
       <img src={ardmore} style={{ width: '65px', height:'143px' }} />
      </div> */}
       <div className="ardmore-section" style={{ backgroundColor: 'white', width: '100%', marginTop: '20px', textAlign: 'center', padding: '20px', borderRadius: '20px' }}>
    {apidata && apidata.data && apidata.data.report && apidata.data.report.whisky && apidata.data.report.whisky.icon && (
        <img src={apidata.data.report.whisky.icon.url} style={{ width: '150px', height: '143px' }} />
    )}
</div>
      </div>
      <div className="flavour-images"style={{marginTop:'-30px'}}>
      <div className="circular-layout" >
      <div className="im-container" style={{marginTop: '-98px',marginLeft:'46px'}}>
      <div className="nose-container">
        <img src={images1[6]} alt="Nose Image" className="large-image" />
      </div>
      <div className="row-container" style={{ display: 'flex',  }}>
  <div className="im-container1">
    {apidata && (
      <div className="image-grid">
        {apidata.data.onthepalate.slice(0,6).map((aroma, index) => (
          <div className="small-image-container" key={index}>
            <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-image" />
          </div>
        ))}
        {apidata.data.onthepalate.length < 6 && apidata.data.randomonthepalate.slice(0, Math.max(0, 6 - apidata.data.onthepalate.length)).map((aroma, index) => (
          <div className="small-image-container" key={index + apidata.data.onthepalate.length}>
            <img src={aroma.icon.url} alt={`On the Nose Image ${index + apidata.data.onthepalate.length + 1}`} className="small-image" />
          </div>
        ))}
      </div>
    )}
  </div>
</div>
      {/* <div className="row-container">
        <div className="im-container1">
        {apidata && [...apidata.data.onthepalate, ...apidata.data.randomonthepalate.slice(0, 1)].map((aroma, index) => (
                  <div className="small-image-container" key={index}>
                  <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-image" />
                  </div>
              ))}
          
        </div>
        <div className="im-container1">
        {apidata && [ ...apidata.data.randomonthepalate.slice(2, 5)].map((aroma, index) => (
                  <div className="small-image-container" key={index}>
                  <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-image" />
                  </div>
              ))}
         
        </div>
      </div> */}
    </div>
      </div>
      </div>
      <div className="flavour-content" style={{color:'rgb(10, 37, 60)',marginTop:'70px'}}>
      <b style={{textDecoration:'underline'}}>{apidata?apidata.data.report.whisky.whiskey_name:''}</b>
        <div className="report-content">
        {apidata && apidata.data.report.ratings !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '16px' }}>
                  {convertRatingToStars(apidata.data.report.ratings)}
                </span>
                <span style={{ color: 'black', fontSize: '16px' }}>
                  {apidata.data.report.ratings}.0
                </span>
              </p>
            )}
        {/* <p>
            <span className="stars" style={{ fontSize: '16px' }} >{convertRatingToStars(apidata.data.report.ratings)}</span>
            <span style={{ color: 'black' }}>{apidata.data.report.ratings}.0</span>
          </p> */}
          {/* <p><span className="stars" style={{fontSize:'18px'}}>★★★★☆</span> {apidata?apidata.data.report.ratings:''}.0</p> */}
        </div>
        <p className="description" style={{ textAlign: 'justify', fontSize: '14px', color: 'rgb(10, 37, 60)', marginTop: '-10px', lineHeight: '1.5' }}>
            {showFullDescription ? fullDescription : visibleWords}
            {!showFullDescription && (
              <span className="read-more" onClick={toggleDescription} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {continuationText}
              </span>
            )}
            {showFullDescription && (
              <span className="read-less" onClick={toggleDescription} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {ReadLess}
              </span>
            )}
          </p>
          {/* <p style={{textAlign:'justify',fontSize:'14px',color:'rgb(10, 37, 60)',marginTop:'-10px'}}>
          {apidata?apidata.data.report.description:''}
          </p> */}
          </div>
          <div className=" justify-content-center align-items-center mb-2 w-72">
            <div className="form-group align-items-center" style={{backgroundColor:'white',width:'350px',height:'113px',paddingRight:'40px',paddingLeft:'40px',paddingBottom:'10px',paddingTop:'10px',borderRadius:'20px',}}>
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginBottom:'-26px'}}>
              <div className="on_the_nose_img" >
              <img src={nose} style={{maxHeight:'27px',maxWidth:'27px'}}/>
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.on_the_nose_rating !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '16px' }}>
                  {convertRatingToStars(apidata.data.report.on_the_nose_rating)}
                </span>
                <span style={{ color: 'black', fontSize: '16px' }}>
                  {apidata.data.report.on_the_nose_rating}.0
                </span>
              </p>
            )}
              {/* <p>
            <span className="stars" style={{ fontSize: '16px' }} >{convertRatingToStars(apidata.data.report.on_the_nose_rating)}</span>
            <span style={{ color: 'black' }}>{apidata.data.report.on_the_nose_rating}.0</span>
          </p> */}
                {/* <p>
                  <span className="stars"style={{fontSize:'18px'}}>★★★★☆</span> {apidata?apidata.data.report.on_the_nose_rating:''}.0
                </p> */}
              </div>
            </div>
            <hr/>
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginBottom:'-26px',marginTop:'-13px',height:'38px' }}>
              <div className="on_the_nose_img">
              <img src={palate} style={{maxHeight:'27px',maxWidth:'27px'}}/>
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.on_the_palate_ratings !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '16px' }}>
                  {convertRatingToStars(apidata.data.report.on_the_palate_ratings)}
                </span>
                <span style={{ color: 'black', fontSize: '16px' }}>
                  {apidata.data.report.on_the_palate_ratings}.0
                </span>
              </p>
            )}
              {/* <p>
            <span className="stars" style={{ fontSize: '16px' }} >{convertRatingToStars(apidata.data.report.on_the_palate_ratings)}</span>
            <span style={{ color: 'black' }}>{apidata.data.report.on_the_palate_ratings}.0</span>
          </p> */}
                {/* <p>
                  <span className="stars" style={{fontSize:'18px'}}>★★★★☆</span> {apidata?apidata.data.report.on_the_palate_ratings:''}.0
                </p> */}
              </div>
            </div>
            <hr/>
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginTop:'-13px' }}>
              <div className="on_the_nose_img">
              <img src={finish} style={{maxHeight:'27px',maxWidth:'27px'}}/>
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.finish_option !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '12px' }}>
                  {convertRatingToStar(apidata.data.report.finish_option)}
                </span>
                <span style={{ color: 'black', fontSize: '12px' }}>
                  {apidata.data.report.finish_option}
                </span>
              </p>
            )}
                {/* <p>
                    
                  {apidata?apidata.data.report.finish_option:''}
                </p> */}
              </div>
            </div>
          </div>
          <button className="smacid-btn"  onClick={() => goToClub()} style={{ float:'left',width:'110px',height:'50px',backgroundColor:'white',borderRadius:'40px',marginTop:'20px',color:'#0A253C',border:'2px solid #0A253C'}} >
            <b style={{fontSize:'18px'}}>PREVIOUS</b>
          </button>
          <button className="smacid-btn"  onClick={() => goToClub2()} style={{float:'right', width:'100px',height:'50px',backgroundColor:'white',borderRadius:'40px',marginTop:'20px',color:'#0A253C',border:'2px solid #0A253C'}} >
            <b style={{fontSize:'18px'}}>NEXT</b>
          </button>
          {/* <button className="smacid-btn" style={{float:'left', width:'383px',height:'64px',backgroundColor:'#0A253C',borderRadius:'40px',marginTop:'20px'}} >
          <span><b style={{fontSize:'20px'}}>SHARE  ON</b> <img src={fb} style={{maxHeight:'40px',marginLeft:'10px',marginRight:'10px',marginBottom:'10px'}}/><b style={{fontSize:'31px'}}>/</b><img src={insta} style={{maxHeight:'21px',maxWidth:'100px',marginLeft:'20px',marginBottom:'10px'}}/></span>
          </button> */}
          {/* <button className="smacid-btn" style={{float:'left', width:'350px',height:'64px',backgroundColor:'white',borderRadius:'40px',marginTop:'20px',color:'#0A253C',border:'2px solid #0A253C'}} >
            <b style={{fontSize:'18px'}}>JOIN THE CLUB!</b>
          </button> */}
          
          </div>
   </div>
   </div>
}
</div>
  );
};

export default Club1;