// cropImage.js

export const getCroppedImg = (imageSrc, crop) => {
    const createImage = url =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
        image.src = url;
      });
  
    const getCroppedImg = async (imageSrc, crop) => {
      const image = await createImage(imageSrc);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      const maxSize = Math.max(image.width, image.height);
      const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
  
      canvas.width = safeArea;
      canvas.height = safeArea;
  
      ctx.translate(safeArea / 2, safeArea / 2);
      ctx.translate(-safeArea / 2, -safeArea / 2);
  
      ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
      );
  
      const data = ctx.getImageData(0, 0, safeArea, safeArea);
  
      canvas.width = crop.width;
      canvas.height = crop.height;
  
      ctx.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - crop.x,
        0 - safeArea / 2 + image.height * 0.5 - crop.y
      );
  
      return new Promise(resolve => {
        canvas.toBlob(blob => {
            resolve(blob);
          }, 'image/jpeg');
      });
    };
  
    return getCroppedImg(imageSrc, crop);
  };
  