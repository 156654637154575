// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .large-image {
    width: 44px;
    height: 43px;
    border-radius: 25%;
    border: 1px solid rgb(10, 37, 60);
    /* margin-bottom: 10px;  */
  }
  
  .small-image{
    width: 18px;
    height: 18px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
  }  
  .flavours{
    margin-top:0px;
  }
  .image-grid2{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(2, auto); 
  }`, "",{"version":3,"sources":["webpack://./src/pages/club2/club2.css"],"names":[],"mappings":";;EAEE;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iCAAiC;IACjC,0BAA0B;EAC5B;;EAEA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,aAAa;IACb,qCAAqC;;EAEvC;EACA;IACE,cAAc;EAChB;EACA;IACE,aAAa;IACb,qCAAqC;IACrC,mCAAmC;EACrC","sourcesContent":["\n  \n  .large-image {\n    width: 44px;\n    height: 43px;\n    border-radius: 25%;\n    border: 1px solid rgb(10, 37, 60);\n    /* margin-bottom: 10px;  */\n  }\n  \n  .small-image{\n    width: 18px;\n    height: 18px;\n  }\n  .grid-container {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    \n  }  \n  .flavours{\n    margin-top:0px;\n  }\n  .image-grid2{\n    display: grid;\n    grid-template-columns: repeat(2, 1fr); \n    grid-template-rows: repeat(2, auto); \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
