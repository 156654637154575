import React from 'react';
import DOMPurify from 'dompurify';

const HtmlContentComponent = ({ htmlContent }) => {
  const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);

  return (
    <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />
  );
};

export default HtmlContentComponent;
