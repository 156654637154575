// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.body{
    font-family: 'Lato', sans-serif !important;
  }
.profilecontainer1{
    height: auto;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.loyalty-points{
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}
.loyalitypoints-profile{
 position: absolute;
 border-radius:50%;
 left: 1px; 
 top: 6px;
 width: 105px;
 height: 107px;
 border: 2px solid black
}
.voucher-btn{
    float: right;
    position: relative;
    top: -62px;
}`, "",{"version":3,"sources":["webpack://./src/pages/loyaltypoints/loyalitypoints.css"],"names":[],"mappings":";AACA;IACI,0CAA0C;EAC5C;AACF;IACI,YAAY;IACZ,WAAW;IACX,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;AAChC;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,8BAA8B;AAClC;AACA;CACC,kBAAkB;CAClB,iBAAiB;CACjB,SAAS;CACT,QAAQ;CACR,YAAY;CACZ,aAAa;CACb;AACD;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd","sourcesContent":["\n.body{\n    font-family: 'Lato', sans-serif !important;\n  }\n.profilecontainer1{\n    height: auto;\n    width: 100%;\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n.loyalty-points{\n    padding: 10px 20px;\n    display: flex;\n    justify-content: space-between;\n}\n.loyalitypoints-profile{\n position: absolute;\n border-radius:50%;\n left: 1px; \n top: 6px;\n width: 105px;\n height: 107px;\n border: 2px solid black\n}\n.voucher-btn{\n    float: right;\n    position: relative;\n    top: -62px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
