import React, { useState, useEffect } from "react";
import mask from "../assets/assest/Mask Group 1.png";
import './Finishonthewhisky/Finishonthewhisky.css';
import { Navigate, useNavigate } from "react-router-dom";
import { FaSearch, FaTimes } from 'react-icons/fa';
import Loader from "./loader/loader.js";
import Modal from 'react-modal';
import backbutton from '../../src/assets/assest/back.jpeg'

const Onthepalate = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [myArray, setMyArray] = useState([]);
  const [searchData, setSearchData] =useState(null);
  const [query, setQuery] = useState('');
  const maxWords=500;
  const test="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac commodo est. Phasellus vitae lectus eget enim feugiat gravida ac vitae risus. Fusce nec augue commodo, hendrerit quam vitae, hendrerit tortor. Mauris tristique, ipsum et malesuada ultrices, elit nulla mattis purus, sit amet consequat elit velit at turpis. Aliquam dapibus odio sit amet ";

  // const history = useHistory();
  const [apiData, setApiData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [thisScreenSelected, setthisScreenSelected] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [category,setCategories]=useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClearInput = () => {
    setQuery('');
    setSearchData(apiData["data"]); 
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      padding: '20px',
      border: 'none',
      position: 'absolute',
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor:'#f9f9f9',
      // backgroundColor: '#fff',
      zIndex: 9999
    }
  };

  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    setMyArray(JSON.parse(localStorage.getItem('selectedSubcategories')));
    setSelectedCategories([...selectedCategories, ...myArray]);
    if(localStorage.getItem('isActive')=='0')
    {
      const a=JSON.parse(localStorage.getItem('on_the_nose'));
      if(a==null)
      {
        setSelectedItems([]);
      }
      else
      {
        setSelectedItems(a);
      }
    }
    else
    {
      const b=JSON.parse(localStorage.getItem('on_the_palate'));
      if(b==null)
      {
        setSelectedItems([]);
      }
      else
      {
        setSelectedItems(b);
      }
    }
  async function fetchData() {
    const formData = new FormData();
    formData.append('category', localStorage.getItem('nose_category'));
      setTimeout(() => {
        fetch('https://admin.smacflavourfolio.com/api/user/subcategories', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log(JSON.parse(JSON.stringify(data))["data"]);
        setApiData(JSON.parse(JSON.stringify(data)));
        setSearchData(JSON.parse(JSON.stringify(data))["data"]);
        setIsLoading(false);
        // if(apiData["errors"])
        // {
        //    console.log('result',apiData["errors"]);
        // }
        // else
        // {
        //   console.log('result',apiData["errors"]);
        // }
      });
      }, 2000);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (searchData != null) {
      searchData.map((item) => {
        if (selectedItems.includes(item["id"]) && !thisScreenSelected.includes(item["id"])) {
          console.log("length", thisScreenSelected.length);
          setthisScreenSelected([...thisScreenSelected, item["id"]]);
          console.log(item["id"]);
        }
      });
    }
  }, [apiData, thisScreenSelected]);  // Added thisScreenSelected as a dependency
  

  const addSubcategory = (id) => {

    if (selectedItems.includes(id)) {

      setSelectedItems(selectedItems.filter((item) => item !== id));
      setthisScreenSelected(thisScreenSelected.filter((item) => item !== id));

    } else {

      setthisScreenSelected([...thisScreenSelected, id]);
      setSelectedItems([...selectedItems, id]);

    }

  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setQuery(value);
    if(value.length>0)
    {
    const filteredItems = apiData["data"].filter((item) =>
    item["name"].toLowerCase().includes(value.toLowerCase())
    );
    console.log('search data',filteredItems);
     setSearchData(filteredItems);
    }
    else
    {
      setSearchData(apiData["data"]); 
    }
  };

  const handlePopstate = () => {
    if(thisScreenSelected.length>0)
    {
      setModalIsOpen(true);
      // window.history.back();
    }
    else
    {
      window.history.back();
    }
  };

  const moveBack = () =>{
    window.history.back();
  }

  const navigateToScreen = () => {

    const concatenatedArray = [...selectedCategories, ...selectedItems];
      localStorage.setItem('selectedSubcategories',JSON.stringify(concatenatedArray));
    if(localStorage.getItem('isActive')=='0')
    {
      setCategories(localStorage.getItem('completed_nose'));
      const b=JSON.parse(localStorage.getItem('completed_nose'));
      if(b!=null)
      {
      const a=[...b,localStorage.getItem('nose_category')];
      localStorage.setItem('completed_nose',JSON.stringify(a));
      localStorage.setItem('on_the_nose',localStorage.getItem('selectedSubcategories'));
      console.log('completed_nose',localStorage.getItem('selectedSubcategories'));
      }
      else
      {
        const a=[...[],localStorage.getItem('nose_category')];
      localStorage.setItem('completed_nose',JSON.stringify(a));
      localStorage.setItem('on_the_nose',localStorage.getItem('selectedSubcategories'));
      console.log('completed_nose',localStorage.getItem('selectedSubcategories'));
      }
    }
    else
    {
      setCategories(localStorage.getItem('completed_palate'));
      const b=JSON.parse(localStorage.getItem('completed_palate'));
      if(b!=null)
      {
      const a=[...b,localStorage.getItem('nose_category')];
      localStorage.setItem('completed_palate',JSON.stringify(a));
      localStorage.setItem('on_the_palate',localStorage.getItem('selectedSubcategories'));
      console.log('completed_nose_palate',localStorage.getItem('selectedSubcategories'));
      }
      else
      {
        const a=[...[],localStorage.getItem('nose_category')];
      localStorage.setItem('completed_palate',JSON.stringify(a));
      localStorage.setItem('on_the_palate',localStorage.getItem('selectedSubcategories'));
      console.log('completed_nose_palate',localStorage.getItem('selectedSubcategories'));
      }
    }
      window.history.back();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<div class="dashboardn-background p-2">
         <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer',marginTop:'1px',marginLeft:'-5px' }}
                 onClick={()=>handlePopstate()}
                >
                <div className='gradient-border'>
                  <img src={backbutton} style={{cursor:'pointer',width:'50px',height:'50px',borderRadius:'13px'}}/>
                </div>
              </button>
            </div>
        {/* <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer' }}
              
                onClick={()=>handlePopstate()}
              >
                
                <b style={{textDecoration: 'underline',fontSize:'14px',cursor:'pointer'}}>{localStorage.getItem('isActive')==0?'GO TO ON THE NOSE':'GO TO ON THE PALATE'}</b>
              </button>
            </div> */}
            <br />
        <h5><b>{localStorage.getItem('name')}</b></h5>

        <div className="row">
          <div className="col-12">
          <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        placeholder="Search..."
        className="design_search"
        value={query}
        onChange={handleInputChange}
        style={{ width: '100%', height: '40px', paddingRight: '35px', paddingLeft: '5px' }}
      />
      {query && (
        <div
        style={{
          position: 'absolute',
          top: '45%',
          right: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex:'9999',
          color: '#000', // Change the color of the clear icon as needed
        }}
        onClick={handleClearInput}
      >
        <FaTimes />
      </div>
      )}
      {/* <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          color: '#999', // Change the color of the search icon as needed
        }}
      >
        <FaSearch />
      </div> */}
    </div>
            </div>
            {/* <div className='col-12 d-flex justify-content-center mt-4'>
               <b style={{fontSize:'18px'}}> ON THE NOSE</b>
            </div> */}
          </div>

        <p style={{fontSize:'16px',paddingTop:'15px'}}>{apiData['data'][0]['description']??''.split(/\s+/).filter((word) => word.length > 0).slice(0, maxWords).join(' ')}</p>
        <div class="container text-center">
          <div class="row">

          {searchData.map((data) => (
            <div class="col-6 col-lg-6 col-md-6 col-sm-6" style={{cursor:'pointer'}} onClick={()=>addSubcategory(data["id"])}>
              <div class="outerbox mb-3 justify-content-between"
              style={{boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',background:'#F4F6FC'}}
              >
              <h4 style={{textAlign:'center'}}>
              <img className="whiskyBottle" src={`${data['icon']==null ? mask : data['icon']['url']}`} alt="" style={{ width:'100%',padding:'5px',height:'110px' }}/>
              <div class="container" style={{padding:'0px',margin:'0px',justifyContent: 'space-between'}}>
                {/* <div class="box" style={{background:'#C07F1E',marginLeft:'7px',flex: '1',maxWidth:'30px'}}>
                  <h5 class="a">A</h5>
                </div> */}

                <div className="row">
                <div className={`${selectedItems.includes(data["id"])  ? 'col-8' : 'col-12'}`}>
                  <span style={{fontSize:'13px',fontWeight:'bold',textAlign:'left',display: 'inline-block',whiteSpace: 'nowrap',overflow:'hidden',textOverflow: 'ellipsis',paddingLeft:'2px',maxWidth:'100%'}}>{data["name"]}</span>
                </div>
                <div className={`${selectedItems.includes(data["id"]) ? 'col-4 mselected' : 'notselected'}`} style={{paddingRight:'15px',paddingTop:'5px'}}>
                <div className={`box3`} style={{background:'green',float:'right'}}>
                  <i class="fa-solid fa-check"></i>
                </div>
                </div>
                </div>
                {/* <div style={{flex: '3'}}>
                  <strong style={{fontSize:'14px',textAlign:'left',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',paddingLeft:'2px'}}>{data["name"]}</strong>
                </div>
                <div style={{paddingRight:'5px',flex: '1'}}>
                <div className={`box1 ${selectedItems.includes(data["id"]) ? 'col-4 mselected' : 'notselected'}`} style={{background:'green',float:'right'}}>
                  <i class="fa-solid fa-check"></i>
                </div>
                </div> */}
                </div>
                </h4>
              </div>
            </div>
           ))}


          </div>
        </div>
        <div className="d-flex container justify-content-center text-align-center">
          <div className={`mb-3 ${thisScreenSelected.length>0 ? 'visibleClass' : 'notVisibleClass'}`}>
          <button class="fixed-button" onClick={() => navigateToScreen()} style={{backgroundColor:'#0A253C',border:'2px solid #0A253C55i'}}>DONE</button>
            {/* <button class="btn onThplate" onClick={() => navigateToScreen()} style={{backgroundColor:'#0A253C',width:'100%',bottom:'0%',left:'0%',height:'45px',border:'none',borderRadius:'0px'}}>
              <strong>Ok</strong>
            </button> */}
          </div>
        </div>
      </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <p>Selection will not be saved without pressing done</p>
        <button className="btn btn-success" onClick={()=>{moveBack()}}>OK</button>
        <button className="btn btn-success" onClick={()=>{handleCloseModal()}}  style={{backgroundColor:'#0A253C',float:'right'}}>CLOSE</button>
      </Modal>
    </>
  );
};

export default Onthepalate