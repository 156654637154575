import React, { useState, useEffect } from "react";
import "../Mainpage/mainpage.css";
import '../rating.css';
import { Link } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import whisky from "../../assets/assest/Background.png";
import bottle from "../../assets/assest/default-png.png";
import nose from '../../assets/assest/nose.png'
import palate from '../../assets/assest/palate.png'
import finish from '../../assets/assest/finish.png'
import feedback from '../../assets/assest/feedback.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Loader from "../loader/loader.js";
import Slider from 'react-slick';

const ProfileDashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};
const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

useEffect(() => {
  // This code will execute when the component mounts or updates
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);

const handleClick = (param) => {
  localStorage.setItem('type', param);
  localStorage.setItem('isActive', param);
  navigate("/OnTheNose");
  console.log(param);
};

const handleClick1 = (param) => {
  localStorage.setItem('type', '1');
  localStorage.setItem('isActive', '1');
  navigate("/finishonthewhisky");
  console.log(param);
};

const handleClick2 = (param) => {
  localStorage.setItem('type', param);
  localStorage.setItem('isActive', param);
  navigate("/submit");
  console.log(param);
};

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<div className="" style={{ backgroundColor: "#ffffff",overflowX:'hidden' }}>

      <div className="">
                <div>
                <img src={localStorage.getItem('whisky_image')} alt="Image 1" style={{height:'250px',width:'100%',borderBottomLeftRadius:'30px',borderBottomRightRadius:'30px'}}/>
              </div>
      </div>

        <div className="container mt-5 ">
          <h5 className="d-flex justify-content-center align-items-center">
           <strong>WELCOME {localStorage.getItem('member_name')}</strong>
          </h5>
          <h6 className="d-flex justify-content-center align-items-center">
            SUBMIT YOUR REVIEW FOR {localStorage.getItem('whiskey_name')}
          </h6>
        </div>
        <br/>
        <div className="container card-menu mb-5">
          <div className="row">
            <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center  align-items-center card-menu-item card-menu-item-line"
             onClick={() => handleClick('0')} style={{cursor:'pointer'}}>
            {/* <Link to="/OnTheNose" style={{textDecoration:'none'}}> */}
            <h4 style={{textAlign:'center'}}>
                <img className="menu-img" alt="" src={nose}/>
                <br/>
                <span style={{fontSize:'14px',color:'black',textDecoration:'none'}}><b>ON THE NOSE</b></span>
                <div className={`${localStorage.getItem('is_on_the_nose')=='1' ? 'visibleClass' : 'notVisibleClass'}`}>
                <div className={`d-flex  justify-content-center  align-items-center`}>
                <div className={`mbox1`} style={{float:'right'}}>
                  <i class="fa-solid fa-check"></i>
                </div>&nbsp;
                <span style={{color:'#327D01',fontSize:'12px',paddingTop:'3px'}}>Completed</span>
                </div>
                <div className="d-flex">
                {numbers.map((number) => (
                <div class="text-center" style={{paddingRight:'0px'}}>
                    <FontAwesomeIcon icon={faStar} className={`fas fa-star ${localStorage.getItem('on_the_nose_rating')>=number ? 'myselected1' : ''}`} style={{fontSize:'10px'}}/>
                </div>
               ))}
               </div>
                </div>
              </h4>
              {/* </Link> */}
            </div>
            
            
            <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center  align-items-center card-menu-item" 
             onClick={() => handleClick('1')} style={{cursor:'pointer'}}>
            {/* <Link to="/OnTheNose" style={{textDecoration:'none',color:'black',}}> */}
            <h4 style={{textAlign:'center'}}>
              <img className="menu-img2" src={palate} alt=""/>
              <br/>
                <span style={{fontSize:'14px',color:'black'}}><b>ON THE PALATE</b></span>
                <div className={`${localStorage.getItem('is_on_the_palate')=='1' ? 'visibleClass' : 'notVisibleClass'}`}>
                <div className={`d-flex  justify-content-center  align-items-center`}>
                <div className={`mbox1`} style={{float:'right'}}>
                  <i class="fa-solid fa-check"></i>
                </div>&nbsp;
                <span style={{color:'#327D01',fontSize:'12px',paddingTop:'3px'}}>Completed</span>
                </div>
                <div className="d-flex">
                {numbers.map((number) => (
                <div class="text-center" style={{paddingRight:'0px'}}>
                    <FontAwesomeIcon icon={faStar} className={`fas fa-star ${localStorage.getItem('on_the_palate_ratings')>=number ? 'myselected1' : ''}`} style={{fontSize:'10px'}}/>
                </div>
               ))}
               </div>
                </div>
              </h4>
              {/* </Link> */}
            </div>

            <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center card-menu-item card-menu-item-line"
            onClick={()=>handleClick1('2')}
            style={{cursor:'pointer'}}
            >
            <br/>
            {/* <Link to="/finishonthewhisky" style={{textDecoration:'none',color:'black',}}> */}
              <h4 style={{textAlign:'center'}}>
              <img className="menu-img3" style={{textAlign:'center'}} src={finish} alt=""/>
              <br/>
                <span style={{fontSize:'14px',color:'black',paddingLeft:'3px'}}><b>FINISH</b></span>
                <div className={`${localStorage.getItem('finish')=='1' ? 'visibleClass' : 'notVisibleClass'}`}>
                <div className={`d-flex  justify-content-center  align-items-center`}>
                <div className={`mbox1`} style={{float:'right'}}>
                  <i class="fa-solid fa-check"></i>
                </div>&nbsp;
                <span style={{color:'#327D01',fontSize:'12px',paddingTop:'3px'}}>Completed</span>
                </div>
                </div>
              </h4>
              {/* </Link> */}
            </div>
          
            
            <div className="col-6 col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center card-menu-item card-menu-item-line" 
            onClick={()=>handleClick2('3')}
            style={{cursor:'pointer'}}
            >
            <br/>
            {/* <Link to="/submit" style={{textDecoration:'none',color:'black'}}> */}
              <h4 style={{textAlign:'center'}}>
              <br/>
              <img className="menu-img4" src={feedback} alt=""/>
              <br/>
                <p style={{fontSize:'14px',color:'black',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}><b>OVERALL WHISKY RATING</b></p>
              </h4>
              {/* </Link> */}
            </div>
          
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default ProfileDashboard;
