import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './pages/Services/AuthContext';

const PrivateRoute1 = ({ element }) => {
  const isAuthenticated=localStorage.getItem('token');
  return !isAuthenticated ? element : <Navigate to="/profileDashboard" />;
};

export default PrivateRoute1;
