import React, { useState, useEffect } from "react";
import './Finishonthewhisky.css'
import img from '../../assets/assest/flags.png'
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import backbutton from '../../assets/assest/back.jpeg'

const Finishonthewhisky = () => {

  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState('');
  const finishData = (param) =>{
      setSelectedItems(param);
      console.log('selected item',param)
  };

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    // This code will execute when the component mounts or updates
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const navigateToScreen = () => {

    localStorage.setItem('reviee',selectedItems);
    localStorage.setItem('isActive','2');
    localStorage.setItem('finish','1');
    localStorage.setItem('rating',0);
    navigate('/mainpage');

  };

  return (
    <div style={{ minHeight:'100vh' }}>
    <div className="bg p-2" 
    // style={{ background: "#FFFFFF" }}
    >
      <div>
      <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer',marginTop:'1px',marginLeft:'-5px' }}
                 onClick={()=>handleGoBack()}
                >
                  <div className='gradient-border'>
                <img className="gradient-border-img" src={backbutton} style={{cursor:'pointer',width:'50px',height:'50px',borderRadius:'13px'}}/>
              </div>
              </button>
            </div>
        {/* <div>
        <b style={{textDecoration: 'underline',fontSize:'14px',cursor:'pointer'}} onClick={()=>handleGoBack()}>  GO TO MAIN SCREEN </b>
        </div> */}
        </div></div>
    <img className="mt-5 mx-3" src={img} alt="flag" style={{width:'100px'}}/>
      <div class="bg p-2"
      //  style={{ background: "#FFFFFF" }}
       >
        <h5><b>Finish the whisky.!</b></h5>
        <p>The aftertaste and that lingering sensation that your experience</p>
        <div class="container text-center">
          <div class="row">
            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
              <div class="outerbox p-2 mb-3  d-flex justify-content-between" style={{ background: "#FFFFFF",cursor:'pointer' }} onClick={()=>finishData('Abrupt')}>
                {/* <div class="box" style={{background:'#C07F1E'}}>
                  <h5 class="a">A</h5>
                </div> */}
                <div>
                  <strong>Abrupt</strong>
                </div>
                <div>
                <div class="box1" style={{background:'green'}} className={`box1 ${selectedItems=='Abrupt' ? 'myfselected' : 'unfselect'}`}>
                  <i class="fa-solid fa-check"></i>
                </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
            <div class="outerbox p-2 mb-3  d-flex justify-content-between" onClick={()=>finishData('Short')} style={{cursor:'pointer'}}>
                {/* <div class="box" style={{background:'#C07F1E'}}>
                  <h5 class="a">B</h5>
                </div> */}
                <div>
                  <strong>Short</strong>
                </div>
                <div>
                <div class="box1" style={{background:'green'}} className={`box1 ${selectedItems=='Short' ? 'myfselected' : 'unfselect'}`}>
                  <i class="fa-solid fa-check"></i>
                </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
            <div class="outerbox p-2 mb-3  d-flex justify-content-between" onClick={()=>finishData('Medium')} style={{cursor:'pointer'}}>
                {/* <div class="box" style={{background:'#C07F1E'}}>
                  <h5 class="a">C</h5>
                </div> */}
                <div>
                  <strong>Medium</strong>
                </div>
                <div>
                <div style={{background:'green'}} className={`box1 ${selectedItems=='Medium' ? 'myfselected' : 'unfselect'}`}>
                <i class="fa-solid fa-check"></i>
                </div>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
            <div class="outerbox p-2 mb-3  d-flex justify-content-between" onClick={()=>finishData('Almost there')} style={{cursor:'pointer'}}> 
                {/* <div class="box" style={{background:'#C07F1E'}}>
                  <h5 class="a">D</h5>
                </div> */}
                <div>
                  <strong>Almost there</strong>
                </div>
                <div>
                <div class="box1" style={{background:'green'}} className={`box1 ${selectedItems=='Almost there' ? 'myfselected' : 'unfselect'}`}>
                  <i class="fa-solid fa-check"></i>
                </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
            <div class="outerbox p-2 mb-3  d-flex justify-content-between" onClick={()=>finishData('Baby I am home!')} style={{cursor:'pointer'}}>
                {/* <div class="box" style={{background:'#C07F1E'}}>
                  <h5 class="a">E</h5>
                </div> */}
                <div>
                  <strong>Baby I am home!</strong>
                </div>
                <div>
                <div class="box1" style={{background:'green'}} className={`box1 ${selectedItems=='Baby I am home!' ? 'myfselected' : 'unfselect'}`}>
                  <i class="fa-solid fa-check"></i>
                </div>
                </div>
              </div>
            </div>
            <div class="mybtn">
          <button class="btn" onClick={() => navigateToScreen()} style={{backgroundColor:'#0A253C'}}>
            <strong>OK</strong>
            {/* <i class="fa-solid fa-check ms-2"></i> */}
          </button>
        </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Finishonthewhisky;
