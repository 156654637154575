import React, { useEffect, useState } from "react";
import nose from "../../assets/assest/nose.png";
import palate from "../../assets/assest/palate.png";
import finish from "../../assets/assest/finish.png"
import backbutton from '../../assets/assest/back.jpeg';
import home from '../../assets/assest/home.png'
import instagram from '../../assets/assest/instagram.png'
import logo from '../../assets/assest/default.png';
import logo1 from "../../assets/assest/logo.png";
import background1 from "../../assets/assest/bg.png";
import background2 from "../../assets/assest/Theme1.png"
import background3 from "../../assets/assest/Theme2.png"
import {base_url} from '../../constant.js';
import Modal from 'react-modal';
import DescriptionComponent from "../description.js";
import "./whiskyclub.css";
import { useLocation,useNavigate } from 'react-router-dom';
import Loader from "../loader/loader.js";
import newmale from '../../assets/assest/member_level_frames/newbie.png'
import newfemale from '../../assets/assest/member_level_frames/newbie_female.png';
import moderatorMale from '../../assets/assest/member_level_frames/moderator_male.png';
import moderatorFemale from '../../assets/assest/member_level_frames/moderator_female.png';
import expertMale from '../../assets/assest/member_level_frames/expert_male.png';
import expertFemale from '../../assets/assest/member_level_frames/expert_female.png';
import connoisseurMale from '../../assets/assest/member_level_frames/connoisseur_male.png';
import connoisseurFemale from '../../assets/assest/member_level_frames/Connoisseur Female.png';
import enthusiastMale from '../../assets/assest/member_level_frames/enthusiast_male.png';
import enthusiastFemale from '../../assets/assest/member_level_frames/enthusiast_female.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const DesignPage = () => {
  const [apidata,setApiData]= useState(null);
  const [isLoading, setIsLoading]= useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const designId = queryParams.get('design_id');
  const navigate =useNavigate();
  const title = 'Smac Flavour Folio';
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { report_id,selectedColor,useProfile,uploadedImage,useReview } = location.state || {};
  const [hasOnTheNoseRating,sethasOnTheNoseRating] = useState(null);
  const [hasOnThePalateRating,sethasOnThePalateRating] = useState(null);
  const [hasFinishOption,sethasFinishOption] = useState(null);
  const [hasAnyRating,sethasAnyRating] = useState(null);
  const [shareUrl,setShareUrl] =useState('');
  const bgImages = [background1,background2,background3];
  const [profileFrameUrl,setP]=useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    async function fetchData()
    {
      //fetch report
      try {
        const formData = new FormData();
        formData.append('design_id', designId);
        const response = await fetch(base_url+'/reportDetail',{
        method:"POST",
        body:formData,
      });
        const data = await response.json();
        if(data!=null)
        {
            const prUrl = getProfileImage(data.data.report.user.gender, getNextLevel("15"));
            setP(prUrl);
            setApiData(data);
            if(data.data.report.on_the_nose_rating !== "null")
              {
                sethasOnTheNoseRating(true);
              }
            if(data.data.report.on_the_palate_ratings !== "null")
              {
                sethasOnThePalateRating(true);
              }
            if(data.data.report.finish_option !== "null")
              {
                 sethasFinishOption(true);
              }
            if(data.data.report.finish_option !== "null" || data.data.report.on_the_palate_ratings !== "null" || data.data.report.on_the_nose_rating !== "null")
              {
                   sethasAnyRating(true);
              }
            setIsLoading(false);
            setShareUrl(window.location.origin+"/design?design_id="+data.data.report.design_id);
        }
      } catch (error) {
         setIsLoading(false);
        console.error(error);
      }
    }
    fetchData();
}, []);


const togglePopup = (data, title) => {
  setPopupData(data);
  // setPopupTitle(title);
  setShowPopup(!showPopup);
  
}


const getNextLevel = (totalPointsEarned) => {
  if (totalPointsEarned >= 500) {
      return "Moderator";
  } else if (totalPointsEarned >= 301) {
      return "Expert";
  } else if (totalPointsEarned >= 151) {
      return "Connoisseur";
  } else if (totalPointsEarned >= 51) {
      return "Enthusiast";
  } else {
      return "Beginner";
  }
};
const getProfileImage = (gender, level) => {
if (level === "Moderator") {
  return gender === "male" ? moderatorMale : moderatorFemale;
} else if (level === "Expert") {
  return gender === "male" ? expertMale : expertFemale;
} else if (level === "Connoisseur") {
  return gender === "male" ? connoisseurMale : connoisseurFemale;
} else if (level === "Enthusiast") {
  return gender === "male" ? enthusiastMale : enthusiastFemale;
} else {
  return gender === "male" ? newmale : newfemale;
}
};

const handleGoBack = () => {
  window.history.back();
};
const goToHomepage = () =>{
  navigate("/profileDashboard",{state:{report_id:report_id}})
}
const convertRatingToStar = (rating) => {
  let stars = '';
  for (let i = 0; i < rating; i++) {
    stars += '★';
  }
  return stars;
};
const convertRatingToStars = (rating) => {
  const totalStars = 10;
  const filledStars = Math.round(rating);
  const starsArray = Array.from({ length: totalStars }, (v, i) => i < filledStars ? '★' : '☆');
  return starsArray.join('');
};

const fullDescription = apidata ?(apidata.data.report.description) : '';
      const continuationText = "Read more";
      const ReadLess = "Read Less"
      const words = fullDescription.split(' ');
      let maxWords = 30;
      let visibleWords = words.slice(0, maxWords).join(' ');
    
      while (visibleWords.length + continuationText.length > 150 && maxWords > 0) {
        maxWords -= 1;
        visibleWords = words.slice(0, maxWords).join(' ');
      }
    
   
      const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
      };

      if (!apidata) return <div>Loading...</div>;

return (
  <HelmetProvider>
     <Helmet>
      <meta property="og:title" content={"SMAC Flavour Folio: The Ultimate Whisky Rating Platform"} />
      <meta property="og:description" content={apidata.data.report.review || "Discover, rate, and review the finest whiskies from around the world with SMAC Flavour Folio. Join our community of whisky enthusiasts and explore expert ratings and user reviews."} />
      <meta property="og:image" content="https://admin.smacflavourfolio.com/storage/images/design1723205999.png" />
      <meta property="og:url" content={window.location.origin+"/design?design_id="+`${apidata?apidata.data.report.design_id:''}`} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={"SMAC Flavour Folio: The Ultimate Whisky Rating Platform"} />
      <meta name="twitter:description" content={apidata.data.report.review || "Discover, rate, and review the finest whiskies from around the world with SMAC Flavour Folio. Join our community of whisky enthusiasts and explore expert ratings and user reviews."} />
      <meta name="twitter:image" content="https://admin.smacflavourfolio.com/storage/images/design1723205999.png" />
    </Helmet>
  {isLoading ? (
    <Loader />
  ) :
  <div
  className="flavour-container container-fluid"
  style={{
    background: apidata && apidata.data && apidata.data.report && apidata.data.report.color_code
    ? `url(${bgImages[apidata.data.report.color_code]})`
    : '#ffffff',
  fontFamily: 'Lato, sans-serif',
  width: '100%',
  // borderRadius: '20px'
  }}
>
      <div>
</div>

<div className="smac" style={{ paddingTop: '20px', marginBottom: '40px' }}>
  
  {apidata && apidata.data && apidata.data.report.my_profile==1 ? (
    
    <div style={{ position: 'relative', width: '50%',zIndex:"0" }}>
       <img src={profileFrameUrl} alt="Profile Frame" className="profile-frame-img"/>
      <img
        src={apidata && apidata.data.report.user.profile.url}
        // style={{ width: '85%', marginTop: '-2px' }}
        alt="Profile"
        className="profile"
      />
    </div>
  ) : (
    <div style={{ position: 'relative', width: '50%' }}>
      <img src={logo1} style={{ width: '73%', marginTop: '-2px' }} alt="Logo" />
    </div>
  )}
  <div className="ardmore" style={{height:'130px',width:'50%'}}>
    
    <div className="ardmore-section d-flex align-items-center justify-content-center">
     {uploadedImage ? (
         <img src={uploadedImage} className="img-review img-thumbnail" alt="Uploaded" />
     ) : (
         apidata && apidata.data.report.whisky && apidata.data.report.whisky.icon && (
             <img src={apidata.data.report.whisky.icon.url} className="img-review img-thumbnail" alt="Whisky Icon" />
         )
     )}
 </div>   
 </div>
</div>
    
<div className="ardmore">
  <div className="nose-palate-section" >
  <div className="row">
      

        <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
          <div className="nose-container text-center">
              <img src={nose} alt="Nose Image" className="large-images mx-auto d-block" 
                  style={{ width: '80px', height: '80px', borderRadius: '56px' }}
              />
              <p className="text-center">Aromatics</p>
          </div>
      </div>
        <div className="col-8 col-md-8">
        <div className="flavours" >
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1">
              {apidata && (
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px' }}>
                  {apidata.data.onthenose.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.preview_url} alt={`On the Nose Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthenose.length < 6 && apidata.data.randomonthenose.slice(0, Math.max(0, 6 - apidata.data.onthenose.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthenose.length}>
                      <img src={aroma.icon.preview_url} alt={`On the Nose Image ${index + apidata.data.onthenose.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
              )}
              <button className="view-al-btn" style={{ float: 'right' }} 
              onClick={() => togglePopup([...apidata.data.onthenose])}>
                View All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
  </div>
  <div className="row">
        <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
          <div className="nose-container text-center">
              <img src={palate} alt="Nose Image" className="large-images mx-auto d-block" 
                  style={{ width: '80px', height: '80px', borderRadius: '56px' }}
              />
              <p className="text-center">On The Palate</p>
          </div>
      </div>
    <div className="col-8 col-md-8">
    <div className="flavours">
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1">
              {apidata && (
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px'}}>
                  {apidata.data.onthepalate.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.preview_url} alt={`On the Palate Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthepalate.length < 6 && apidata.data.randomonthepalate.slice(0, Math.max(0, 6 - apidata.data.onthepalate.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthepalate.length}>
                      <img src={aroma.icon.preview_url} alt={`On the Palate Image ${index + apidata.data.onthepalate.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
              )}
              <button className="view-al-btn" style={{ float: 'right' }}
              onClick={() => togglePopup([...apidata.data.onthepalate])}>
                View All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
  </div>
 
    
  </div>

  {/* <div className="images-section">
    <div className="flavours" style={{ marginBottom: '20px' }}>
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1">
              {apidata && (
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px' }}>
                  {apidata.data.onthenose.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthenose.length < 6 && apidata.data.randomonthenose.slice(0, Math.max(0, 6 - apidata.data.onthenose.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthenose.length}>
                      <img src={aroma.icon.url} alt={`On the Nose Image ${index + apidata.data.onthenose.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
              )}
              <button className="view-al-btn" style={{ marginTop: '10px' }} 
              onClick={() => togglePopup([...apidata.data.onthenose])}>
                View All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flavours">
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1">
              {apidata && (
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px'}}>
                  {apidata.data.onthepalate.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.url} alt={`On the Palate Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthepalate.length < 6 && apidata.data.randomonthepalate.slice(0, Math.max(0, 6 - apidata.data.onthepalate.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthepalate.length}>
                      <img src={aroma.icon.url} alt={`On the Palate Image ${index + apidata.data.onthepalate.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
              )}
              <button className="view-al-btn" style={{ marginTop: '10px' }}
              onClick={() => togglePopup([...apidata.data.onthepalate])}>
                View All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</div>
      <div className="flavour-content" style={{color:'rgb(10, 37, 60)',marginTop:'10px',padding:'7px'}}>
      <b style={{textDecoration:'underline'}}>{apidata?apidata.data.report.whisky.whiskey_name:''}</b>
        <div className="report-content">
        <p>
            <span className="stars" style={{ fontSize: '16px' }} >{convertRatingToStars(apidata?apidata.data.report.ratings:'')}</span>
            <span style={{ color: 'black' }}>{apidata?apidata.data.report.ratings:''}.0</span>
          </p>
        </div>

          </div>
          <div className=" justify-content-center align-items-center mb-2 w-72">
          {hasAnyRating && (
        <div className="form-group align-items-center" style={{  height: '100px', paddingRight: '40px', paddingLeft: '40px', paddingBottom: '10px', paddingTop: '10px', borderRadius: '20px', border: '2px solid black' }}>
          {hasOnTheNoseRating && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-25px', height: '37px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>Aromatics</h6>
                  {/* <img src={nose} style={{ maxHeight: '23px', maxWidth: '23px' }} /> */}
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars" style={{ fontSize: '16px' }}>
                      {convertRatingToStars(apidata.data.report.on_the_nose_rating)}
                    </span>
                    <span style={{ color: 'black', fontSize: '16px' }}>
                      {apidata.data.report.on_the_nose_rating}.0
                    </span>
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}

          {hasOnThePalateRating && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-26px', marginTop: '-18px', height: '37px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>On the Palate</h6>
                  {/* <img src={palate} style={{ maxHeight: '23px', maxWidth: '23px' }} /> */}
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars" style={{ fontSize: '16px' }}>
                      {convertRatingToStars(apidata.data.report.on_the_palate_ratings)}
                    </span>
                    <span style={{ color: 'black', fontSize: '16px' }}>
                      {apidata.data.report.on_the_palate_ratings}.0
                    </span>
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}

          {hasFinishOption && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '-13px', height: '38px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>Finish</h6>
                  {/* <img src={finish} style={{ maxHeight: '23px', maxWidth: '23px' }} /> */}
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars" style={{ fontSize: '12px' }}>
                      {convertRatingToStar(apidata.data.report.finish_option)}
                    </span>
                    <span style={{ color: 'black', fontSize: '12px' }}>
                      {apidata.data.report.finish_option}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
          {/* <div className="form-group align-items-center" style={{backgroundColor:'white',height:'100px',paddingRight:'40px',paddingLeft:'40px',paddingBottom:'10px',paddingTop:'10px',borderRadius:'20px',border:'2px solid black'}}>
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginBottom:'-25px',height:'37px'}}>
              <div className="on_the_nose_img" >
              <img src={nose} style={{maxHeight:'23px',maxWidth:'23px'}}/>
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.on_the_nose_rating !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '16px' }}>
                  {convertRatingToStars(apidata.data.report.on_the_nose_rating)}
                </span>
                <span style={{ color: 'black', fontSize: '16px' }}>
                  {apidata.data.report.on_the_nose_rating}.0
                </span>
              </p>
            )}
              </div>
            </div>
            <hr/>
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginBottom:'-26px',marginTop:'-18px',height:'37px'}}>
              <div className="on_the_nose_img">
              <img src={palate} style={{maxHeight:'23px',maxWidth:'23px'}}/>
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.on_the_palate_ratings !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '16px' }}>
                  {convertRatingToStars(apidata.data.report.on_the_palate_ratings)}
                </span>
                <span style={{ color: 'black', fontSize: '16px' }}>
                  {apidata.data.report.on_the_palate_ratings}.0
                </span>
              </p>
            )}
              </div>
            </div>
            <hr/>
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%',marginTop:'-13px',height:'38px' }}>
              <div className="on_the_nose_img">
              <img src={finish} style={{maxHeight:'23px',maxWidth:'23px'}}/>
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.finish_option !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '12px' }}>
                  {convertRatingToStar(apidata.data.report.finish_option)}
                </span>
                <span style={{ color: 'black', fontSize: '12px' }}>
                  {apidata.data.report.finish_option}
                </span>
              </p>
            )}
              </div>
            </div>
          </div> */}
          {/* <div className="text-center">
          <button className="smacid-btn" style={{ width:'100px',height:'35px',backgroundColor:'#0A253C',borderRadius:'40px',marginTop:'20px',alignItems:'center'}} >
          <span><b style={{fontSize:'16px',}}>SHARE!</b></span>
          </button>
          </div>
          <div className="text-center">
          <button className="smacid-btn" style={{ width:'70%',height:'40px',backgroundColor:'white',borderRadius:'40px',marginTop:'20px'}} >
           <div className="icons" style={{display:'flex'}}>
           <FacebookShareButton url={shareUrl} quote={title}>
           <FacebookIcon size={32} round />
           </FacebookShareButton>
           <WhatsappShareButton url={shareUrl} title={title}>
           <WhatsappIcon size={32} round />
           </WhatsappShareButton>
           <TwitterShareButton url={shareUrl} title={title}>
           <TwitterIcon size={32} round />
          </TwitterShareButton>
          <InstapaperShareButton url={shareUrl} title={title}>
           <InstapaperIcon size={32} round />
           </InstapaperShareButton>

           </div>
          </button>
          </div> */}
          {/* <div className="text-center">
          <button className="smacid-btn" style={{height:'40px',width:'70%',border:'#0A253C',borderRadius:'40px',marginTop:'20px',marginBottom:'10px',background:'white',color:'#0A253C'}} onClick={goToHomepage} >
          <span><b style={{fontSize:'20px'}}>Go to HomePage!</b></span>
          </button>
          </div> */}
          </div>
          <div class="mb-2">
          {(apidata &&
            apidata.data &&
            apidata.data.report &&
            apidata.data.report.use_review==1 ? <p className="description">{apidata && apidata.data.report.review}</p> : 
              <DescriptionComponent apidata={apidata} />
      )}
          </div>
   {/* </div> */}
   </div>
}
<Modal
  isOpen={showPopup}
  onRequestClose={() => setShowPopup(false)}
  contentLabel="View All Popup"
  style={{
    content: {
      position: 'absolute', 
      // height: 'fit-content',
      width: '80%',
      margin: 'auto',
      overflow: 'auto'
    }
  }}
>
  <button onClick={() => setShowPopup(false)} className="close-button">&times;</button>
  <div className="popup-grid">
    {popupData.map((item, index) => (
      <div key={index} className="popup-item">
        <img src={item.icon.url} alt={item.name} className="popup-image" />
        <p style={{ fontSize: '12px', fontWeight:'bold'}}>{item.name}</p>
      </div>
    ))}
  </div>
</Modal>
</HelmetProvider>
  );
};

export default DesignPage;