import React, { useEffect } from "react";
import logo from "../../assets/assest/logo.png";
import { useNavigate } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import background from '../../assets/assest/bg.png';
const Logo = () => {
  const navigate = useNavigate();

  useEffect(() => {
    //Idle timer
    setTimeout(() => {
        navigate("/dashboard");
      }, 2 * 1000); //30 sec idle time
  }, []);
  return (

    <div className="flavour-folio-container">
    <img src={background} alt="Background" className="background-image" />

      <div
        className="h-100 w-100 d-flex justify-content-center align-items-center position-relative "
        style={{ top: "50", left: "50" }}
      >
        <img
          src={logo}
          alt="img"
          style={{ height: "8rem",cursor:'pointer' }}
        />
      </div>
 </div>
  );
};

export default Logo;
