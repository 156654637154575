// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.im-container {
    display: flex;
    align-items: center;
  }
  .large-image {
    width: 44px;
    height: 43px;
    border-radius: 25%;
    border: 1px solid rgb(10, 37, 60);
  }
  
  .row-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .im-container1 {
    /* padding-top: 10px; */
    justify-content: center;
    margin-bottom: 2px;
  }
  .im-container {
    display: flex !important;
    align-items: center;
    /* margin-left: 84px; */
    position: relative;
}
  .small-image {
    width: 20px; 
    height:20px;
    border-radius: 20%;
    margin: 0 2px; 
    border: 1px solid rgb(10, 37, 60);
  }
  .im-container{
    /* margin-left: 60px; */
    position: relative;
  }
  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: repeat(2, auto);
    gap: 0px; 
  }`, "",{"version":3,"sources":["webpack://./src/pages/club1/club1.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iCAAiC;EACnC;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,uBAAuB;IACvB,kBAAkB;EACpB;EACA;IACE,wBAAwB;IACxB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;EACE;IACE,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,iCAAiC;EACnC;EACA;IACE,uBAAuB;IACvB,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,qCAAqC;IACrC,mCAAmC;IACnC,QAAQ;EACV","sourcesContent":[".im-container {\n    display: flex;\n    align-items: center;\n  }\n  .large-image {\n    width: 44px;\n    height: 43px;\n    border-radius: 25%;\n    border: 1px solid rgb(10, 37, 60);\n  }\n  \n  .row-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  \n  .im-container1 {\n    /* padding-top: 10px; */\n    justify-content: center;\n    margin-bottom: 2px;\n  }\n  .im-container {\n    display: flex !important;\n    align-items: center;\n    /* margin-left: 84px; */\n    position: relative;\n}\n  .small-image {\n    width: 20px; \n    height:20px;\n    border-radius: 20%;\n    margin: 0 2px; \n    border: 1px solid rgb(10, 37, 60);\n  }\n  .im-container{\n    /* margin-left: 60px; */\n    position: relative;\n  }\n  .image-grid {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr); \n    grid-template-rows: repeat(2, auto);\n    gap: 0px; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
