// import React,{useState} from 'react';
// import { useParams } from 'react-router-dom';
// import minimalistImage from '../../assets/assest/minimalist.jpg';
// import whisky from '../../assets/assest/whisky.jpg'
// import blackdoglogo from '../../assets/assest/black-dog.png'
// import minimalistLogo from '../../assets/assest/Minimalist-logo.png'; 
// import backbutton from '../../assets/assest/back.jpeg'; 


// const rewardData = [
//   { id: 1, discount: '200 off', brand: 'BLACK DOG', Points:'100',description: 'on orders above ₹499', liked: '47k liked', image: whisky,logo: blackdoglogo, details: 'Extra details about Minimalist discount offer...' ,expiryDate:'October 13,2024',maxDiscount:'200'},
//   { id: 2, discount: 'Buy 2 Get 2 Free', brand: 'BLACK DOG',Points:'200', description: 'on blackdog products sitewide', liked: '13d left', image: whisky,logo: blackdoglogo, details: 'Extra details about blackdog offer...',expiryDate:'September 31,2024',maxDiscount:'200' },
//   { id: 3, discount: '30 - ₹200 off', brand: 'BLACK DOG', Points:'400',description: '+ ₹50 free wallet cash on your 1st order', liked: '13k liked',image: whisky,logo: blackdoglogo, details: 'Extra details about Zepto offer...',expiryDate:'October 13,2024',maxDiscount:'200' },
//   { id: 4, discount: '5 - ₹50 cashback', brand: 'BLACK DOG',Points:'600', description: 'on your next data top-up', liked: '64k liked', image: whisky, logo: blackdoglogo,details: 'Extra details about Google cashback offer...',expiryDate:'October 13,2024',maxDiscount:'200' },
// ];

// const RewardDetailsPage = () => {
  
  
//   const { id } = useParams();
//   const reward = rewardData.find(r => r.id === parseInt(id));
//   const handlePopstate = () => {
//     window.history.back();
//   };
//   const [showDetails, setShowDetails] = useState(false); // State for Details
//   const [showTerms, setShowTerms] = useState(false);    // State for Terms and Conditions
//   const [showRedeem, setShowRedeem] = useState(false);  // State for Redeem

//   // Toggle functions
//   const toggleDetails = () => setShowDetails(!showDetails);
//   const toggleTerms = () => setShowTerms(!showTerms);
//   const toggleRedeem = () => setShowRedeem(!showRedeem);
  
//   if (!reward) {
//     return <div>Reward not found</div>;
//   }

//   return (
//     <div className='container'>
//        <div className="reward-container">
//        <div className='row'>
//     <div className="position-absolute mt-2 mb-5">
//     <button
//       type="button"
//       className="btn-link"
//       style={{
//         color: 'white',
//         border: 'none',
//         background: 'transparent',
//         cursor: 'pointer',
        
//       }}
//       onClick={handlePopstate}
//     >
//       <div className='gradient-border'>
//         <img src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px',borderRadius:'13px' }} alt="Back" />
//         </div>
//     </button>
//   </div>
  
//   <div style={{ position: 'relative' }}>
//   <div style={{ position: 'absolute', right: '10px', top: '20px' }}>
//   <button className="get-code-btn">3000 Points</button>
//   </div>
// </div>

//   </div>
//   {/* Whisky Image */}
//   <div className="reward-top mt-2">
//     <img src={reward.image} alt={`${reward.brand} product`} className="reward-details-image"/>
//   </div>
// {/* </div> */}

//     <div className="reward-details">
//   <div className="reward-info">
//   <div className="brand-logo">
//   <img src={reward.logo} alt={`${reward.brand} logo`} className="brand-logo-img" />
//   <span className="brand-name">{reward.brand}</span>
// </div>
// <h2 className="reward-title">Flat ₹{reward.discount} off</h2>
//     <p className="points">from {reward.brand} on points above {reward.Points}</p>
//     <button className="get-code-btn">100 Points</button>
//   </div>
  
//   <div className="reward-details-section">
//   <div className="terms-header" onClick={toggleDetails} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
//   <span>Details</span>
//   <span style={{ transform: showDetails ? 'rotate(180deg) scaleX(1.5)' : 'rotate(0) scaleX(1.5)', transition: 'transform 0.3s' }}>v</span>
// </div>
  
//   {showDetails && (
//     <ul className="terms-list">
//       <li>Expires on {reward.expiryDate}</li>
//       <li>Minimum {reward.minPoints} spend</li>
//       <li>Maximum ₹{reward.maxDiscount} off</li>
//       <li>{reward.brand} is a science-backed high-performance personal care brand.</li>
//       <li>The voucher cannot be clubbed with any other offer.</li>
//     </ul>
//   )}
// </div>
// {/* Redeem Section */}
// <div className="redeem-section mt-2">
//         <div className="terms-header" onClick={toggleRedeem} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
//           <span>How to Redeem</span>
//           <span style={{ transform: showRedeem ? 'rotate(180deg) scaleX(1.5)' : 'rotate(0) scaleX(1.5)', transition: 'transform 0.3s' }}>v</span>
//         </div>
//         {showRedeem && (
//           <div className="redeem-details">
//             <p>Follow these steps to redeem your reward:</p>
//             <ol  className="terms-list">
//               <li>Visit the {reward.brand} website.</li>
//               <li>Apply the code at checkout.</li>
//               <li>Enjoy your discount of ₹{reward.maxDiscount}!</li>
//             </ol>
//           </div>
//         )}
//       </div>
  
  
//   <div className="terms-and-conditions mt-2">
//   <div className="terms-header" onClick={toggleTerms} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
//   <span>Terms and Conditions</span>
//   <span style={{ transform: showTerms ? 'rotate(180deg) scaleX(1.5)' : 'rotate(0) scaleX(1.5)', transition: 'transform 0.3s' }}>v</span>
// </div>
//     {showTerms && (
//           <ul className="terms-list">
//             <li>Offer valid till {reward.termsExpiry}</li>
//             <li>Cannot be used with other discounts.</li>
//             <li>Available for limited time only.</li>
//             {/* Add more terms as needed */}
//           </ul>
//         )}
//         <div className='col-6 align-items-center'>
//             <a href='' className='link'>Report an issue</a>
//         </div>
//       <button className="get-code-btn">Buy Voucher</button>
//       </div>
//       </div>
// </div>
// </div>
//   );
// };

// export default RewardDetailsPage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import minimalistImage from '../../assets/assest/minimalist.jpg';
import whisky from '../../assets/assest/whisky.jpg';
import blackdoglogo from '../../assets/assest/black-dog.png';
import minimalistLogo from '../../assets/assest/Minimalist-logo.png'; 
import backbutton from '../../assets/assest/back.jpeg'; 
import logo from '../../assets/assest/default.png'

const RewardDetailsPage = () => {
  const { id } = useParams();
  const [reward, setReward] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = '541|u3brXByBatbjpNUv0VNMHy4RTvAJTBJKBL8yQeph994220bd'; 

  useEffect(() => {
    const fetchRewardData = async () => {
      try {
        const response = await fetch('http://127.0.0.1:8000/api/user/getVoucherPoints', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();

        if (!result.errors) {
          const fetchedReward = result.data.find(r => r.id === parseInt(id));
          setReward(fetchedReward);
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchRewardData();
  }, [id, token]);

  const handlePopstate = () => {
    window.history.back();
  };

  // State for toggles
  const [showDetails, setShowDetails] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showRedeem, setShowRedeem] = useState(false);

  // Toggle functions
  const toggleDetails = () => setShowDetails(!showDetails);
  const toggleTerms = () => setShowTerms(!showTerms);
  const toggleRedeem = () => setShowRedeem(!showRedeem);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!reward) {
    return <div>Reward not found</div>;
  }

  return (
    <div className='container'>
      <div className="reward-container">
        <div className='row'>
          <div className="position-absolute mt-2 mb-5">
            <button
              type="button"
              className="btn-link"
              style={{
                color: 'white',
                border: 'none',
                background: 'transparent',
                cursor: 'pointer',
              }}
              onClick={handlePopstate}
            >
              <div className='gradient-border'>
                <img src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px', borderRadius: '13px' }} alt="Back" />
              </div>
            </button>
          </div>

          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: '10px', top: '20px' }}>
              <button className="get-code-btn">{reward.points} Points</button>
            </div>
          </div>
        </div>

        {/* Whisky Image */}
        <div className="reward-top mt-2">
          <img src={reward.images[0]?.url} alt={`${reward.title} product`} className="reward-details-image" />
        </div>

        <div className="reward-details">
          <div className="reward-info">
            <div className="brand-logo">
              <img src={logo} alt={`${reward.title} logo`} className="brand-logo-img" />
              <span className="brand-name">{reward.title}</span>
            </div>
            {/* <h2 className="reward-title">Flat ₹{reward.discount} off</h2> */}
            <p className="points"> {reward.points} points</p>
            <button className="get-code-btn">{reward.points} points</button>
          </div>

          <div className="reward-details-section">
            <div className="terms-header" onClick={toggleDetails} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              <span>Details</span>
              <span style={{ transform: showDetails ? 'rotate(180deg) scaleX(1.5)' : 'rotate(0) scaleX(1.5)', transition: 'transform 0.3s' }}>v</span>
            </div>

            {showDetails && (
               <ul className="terms-list">
               {reward.details ? (
                 <li>{reward.details}</li>
               ) : (
                 <li>No details available</li>
               )}
             </ul>
            )}
          </div>

          {/* Redeem Section */}
          <div className="redeem-section mt-2">
            <div className="terms-header" onClick={toggleRedeem} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              <span>How to Redeem</span>
              <span style={{ transform: showRedeem ? 'rotate(180deg) scaleX(1.5)' : 'rotate(0) scaleX(1.5)', transition: 'transform 0.3s' }}>v</span>
            </div>
            {showRedeem && (
              <div className="redeem-details">
                <p>Follow these steps to redeem your reward:</p>
                <ol className="terms-list">
                {reward.how_to_redeem.split('\r\n').map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ol>
                {/* <ol className="terms-list">
                  <li>Visit the {reward.title} website.</li>
                  <li>Apply the code at checkout.</li>
                  <li>Enjoy your discount of ₹{reward.discount}!</li>
                </ol> */}
              </div>
            )}
          </div>

          <div className="terms-and-conditions mt-2">
            <div className="terms-header" onClick={toggleTerms} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
              <span>Terms and Conditions</span>
              <span style={{ transform: showTerms ? 'rotate(180deg) scaleX(1.5)' : 'rotate(0) scaleX(1.5)', transition: 'transform 0.3s' }}>v</span>
            </div>
            {showTerms && (
               <ul className="terms-list">
               {reward.terms_and_conditions ? (
                 <li>{reward.terms_and_conditions}</li>
               ) : (
                 <li>No details available</li>
               )}
             </ul>
              // <ul className="terms-list">
              //   {/* Replace with actual terms data */}
              //   <li>Offer valid till {reward.updated_at}</li>
              //   <li>Cannot be used with other discounts.</li>
              //   <li>Available for limited time only.</li>
              // </ul>
            )}
            
            <button className="get-code-btn">Buy Voucher</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardDetailsPage;
