import React, { useEffect, useState } from "react";
import profile from "../../assets/assest/profile icon.png";
import nose from "../../assets/assest/nose.png";
import palate from "../../assets/assest/palate.png";
import finish from "../../assets/assest/finish.png";
import fb from '../../assets/assest/fb.png';
import insta from '../../assets/assest/insta.png';
import "./club.css";
import backbutton from '../../assets/assest/back.jpeg';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../loader/loader.js";

const Club = () => {
  const images1 = [nose, palate, finish];
  const [apidata, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { report_id } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        const formData = new FormData();
        formData.append('report_id', report_id);
        const response = await fetch('https://admin.smacflavourfolio.com/api/user/reportDetail', {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
        });
        const data = await response.json();
        if (data) {
          console.log("data ", data);
          setApiData(data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
    fetchData();
  }, [report_id]);
  
  const [useProfile, setUseProfile] = useState(false);

  const handleProfileCheckboxChange = () => {
    setUseProfile(!useProfile);
  };
  const calculatePosition = (index, total) => {
    const angle = (index / total) * 2 * Math.PI;
    const radius = 35; 
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    return { x, y };
  };

  const goToClub = () => {
    navigate("/profile_details", { state: { report_id: report_id } });
  };

  const goToClub1 = () => {
    navigate("/club1", { state: { report_id: report_id } });
  };
  const convertRatingToStar = (rating) => {
    let stars = '';
    for (let i = 0; i < rating; i++) {
      stars += '★';
    }
    return stars;
  };
  const convertRatingToStars = (rating) => {
    const totalStars = 10;
    const filledStars = Math.round(rating);
    const starsArray = Array.from({ length: totalStars }, (v, i) => i < filledStars ? '★' : '☆');
    return starsArray.join('');
  };
  if (isLoading) {
    return <Loader />;
  }

  if (!apidata) {
    return <div>Error: No data available</div>;
  }
      let onTheNoseImages = [];
      let randomOnTheNoseImages = [];


      onTheNoseImages = apidata.data.onthenose.slice(0, 6).map(aroma => aroma.icon.url);
      const remainingNoseImagesCount = 7 - onTheNoseImages.length;
      randomOnTheNoseImages = apidata.data.randomonthenose.slice(0, remainingNoseImagesCount).map(aroma => aroma.icon.url);
      const images = [...onTheNoseImages, ...randomOnTheNoseImages];
      let onThePalateImages = [];
      let randomOnThePalateImages = [];
      onThePalateImages = apidata.data.onthepalate.slice(0, 6).map(aroma => aroma.icon.url);
      const remainingImagesCount = 7 - onThePalateImages.length;
      randomOnThePalateImages = apidata.data.randomonthepalate.slice(0, remainingImagesCount).map(aroma => aroma.icon.url);
      const image1 = [...onThePalateImages, ...randomOnThePalateImages];

      const fullDescription = apidata ?(apidata.data.report.description) : '';
      const continuationText = "Read more";
      const ReadLess = "Read Less"
      const words = fullDescription.split(' ');
      let maxWords = 30;
      let visibleWords = words.slice(0, maxWords).join(' ');
    
      while (visibleWords.length + continuationText.length > 150 && maxWords > 0) {
        maxWords -= 1;
        visibleWords = words.slice(0, maxWords).join(' ');
      }
    
      // Function to toggle showing full description
      const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
      };
      const handlePopstate = () => {
        window.history.back();
      };

      const getTONextPage=()=>{
        navigate('/whiskyclub',{state:{report_id:report_id}});
      };

      const changeDesignPage=()=>{
        navigate('/club1',{state:{report_id:report_id}});
      };


  return (
    <div className="flavour-container container-fluid position-absolute" style={{ backgroundColor: '#F7F9FD',fontFamily:'Lato,sans-serif' }}>
      <div className="flavour-container">
      {/* <div className="position-absolute" style={{ marginTop: '-246px', marginLeft:'4px',fontFamily: 'Lato, sans-serif'  }}> */}
        <div>
          <button
            type="button"
            className="btn-link"
            style={{
              color: 'white',
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
            }}
            onClick={handlePopstate}
          >
            <img src={backbutton} style={{ cursor: 'pointer', width: '35px', height: '35px' }} alt="Back" />
          </button>
        </div>
      {/* </div> */}
      {/* <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer' }}
              
                onClick={()=>handlePopstate()}
              >
                <i className="fa-solid fa-chevron-left mx-2" style={{cursor:'pointer'}}></i>Back
              </button>
            </div> */}
        {/* <div className="w-100 d-flex justify-content-center" style={{ marginLeft: '10px' }}>
          <img src={apidata.data.report.user.profile.url} className="profile-pic" style={{ bottom: '0px', border: '2px solid black' }} alt="profile-img" />
        </div> */}
        <div className="w-100 d-flex justify-content-center" style={{ marginLeft: '10px' }}>
        {useProfile && (
          <div className="profile-container" style={{textAlign:'center'}}>
            <img src={apidata.data.report.user.profile.url} className="profile-pic1" alt="profile-img" />
          </div>
        )}
        </div>
        <div className="name text-center">
          <b>{apidata.data.report.user.name}</b>
        </div>
        <div className="flavours">
          <div className="circular-layout1">
            {images.slice(0, -1).map((image, index) => {
              const position = calculatePosition(index, images.length - 1);
              return (
                <div
                  key={index}
                  className="image-container"
                  style={{ top: `calc(50% - 40px + ${position.y}px)`, left: `calc(50% - 40px + ${position.x}px)` }}
                >
                  <img src={image} alt={`Image ${index + 1}`} className="circular-image" />
                </div>
              );
            })}
            <div
              key="center"
              className="image-container center-image"
              style={{ top: 'calc(50% - 40px)', left: 'calc(50% - 40px)' }}
            >
              <img src={nose} alt="nose-img" className="circular-image"/>
              {/* <img src={images[images.length - 1]} alt="Center Image" className="circular-image" /> */}
            </div>
          </div>
          <div className="ardmore-section" style={{ backgroundColor: 'white', width: '100%', marginTop: '20px', textAlign: 'center', padding: '20px', borderRadius: '20px' }}>
            {apidata.data.report.whisky.icon && (
              <img src={apidata.data.report.whisky.icon.url} style={{ width: '150px', height: '143px' }} />
            )}
          </div>
          </div>
          <div className="circular-layout1" style={{marginLeft:'160px',marginTop:'26px'}}>
            {image1.slice(0, -1).map((image, index) => {
              const position = calculatePosition(index, image1.length - 1);
              return (
                <div
                  key={index}
                  className="image-container"
                  style={{ top: `calc(50% - 40px + ${position.y}px)`, left: `calc(50% - 40px + ${position.x}px)` }}
                >
                  <img src={image} alt={`Image ${index + 1}`} className="circular-image" />
                </div>
              );
            })}
            <div
              key="center"
              className="image-container center-image"
              style={{ top: 'calc(50% - 40px)', left: 'calc(50% - 40px)' }}
            >
              <img src={palate} alt="Center Image" className="circular-image" />
            </div>
          </div>
        
        <div className="flavour-content" style={{ color: 'rgb(10, 37, 60)' }}>
          <b style={{ textDecoration: 'underline' }}>{apidata.data.report.whisky.whiskey_name}</b>
          <div className="report-content">
          {apidata && apidata.data.report.ratings !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '16px' }}>
                  {convertRatingToStars(apidata.data.report.ratings)}
                </span>
                <span style={{ color: 'black', fontSize: '16px' }}>
                  {apidata.data.report.ratings}.0
                </span>
              </p>
            )}
          </div>
           <p className="description" style={{ textAlign: 'justify', fontSize: '14px', color: 'rgb(10, 37, 60)', marginTop: '-10px', lineHeight: '1.5' }}>
            {showFullDescription ? fullDescription : visibleWords}
            {!showFullDescription && (
              <span className="read-more" onClick={toggleDescription} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {continuationText}
              </span>
            )}
            {showFullDescription && (
              <span className="read-less" onClick={toggleDescription} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {ReadLess}
              </span>
            )}
          </p>
        </div>
        <div className="justify-content-center align-items-center mb-2 w-72">
          <div className="form-group align-items-center" style={{ backgroundColor: 'white', width: '350px', height: '113px', paddingRight: '40px', paddingLeft: '40px', paddingBottom: '10px', paddingTop: '10px', borderRadius: '20px' }}>
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-26px' }}>
              <div className="on_the_nose_img">
                <img src={nose} style={{ maxHeight: '27px', maxWidth: '27px' }} />
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.on_the_nose_rating !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '16px' }}>
                  {convertRatingToStars(apidata.data.report.on_the_nose_rating)}
                </span>
                <span style={{ color: 'black', fontSize: '16px' }}>
                  {apidata.data.report.on_the_nose_rating}.0
                </span>
              </p>
            )}
              </div>
            </div>
            <hr />
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-26px', marginTop: '-13px',height:'38px' }}>
              <div className="on_the_nose_img">
                <img src={palate} style={{ maxHeight: '27px', maxWidth: '27px' }} />
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.on_the_palate_ratings !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '16px' }}>
                  {convertRatingToStars(apidata.data.report.on_the_palate_ratings)}
                </span>
                <span style={{ color: 'black', fontSize: '16px' }}>
                  {apidata.data.report.on_the_palate_ratings}.0
                </span>
              </p>
            )}
              </div>
            </div>
            <hr />
            <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '-13px' }}>
              <div className="on_the_nose_img">
                <img src={finish} style={{ maxHeight: '27px', maxWidth: '27px' }} />
              </div>
              <div className="on_the_nose_rating">
              {apidata && apidata.data.report.finish_option !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '12px' }}>
                  {convertRatingToStar(apidata.data.report.finish_option)}
                </span>
                <span style={{ color: 'black', fontSize: '12px' }}>
                  {apidata.data.report.finish_option}
                </span>
              </p>
            )}
              </div>
            </div>
          </div>
          <div className="checkbox-container d-flex justify-content-center align-items-center mb-2 w-72">
          <div className="form-group d-flex align-items-center" style={{backgroundColor:'white',width:'395px',height:'64px',padding:'10px',borderRadius:'20px',marginTop:'20px'}}>
            <label className="mb-0 mr-2" htmlFor="useProfilePicture">
              <input
                type="checkbox"
                id="useProfilePicture"
                className="form-check-input mr-2"
                checked={useProfile}
                onChange={handleProfileCheckboxChange}
              />
              <span className="checkbox-text">Use My Profile</span>
            </label>
          </div>
        </div>
        <div className="buttons" style={{marginTop:'20px'}}>
        <button className="smacid-btn" style={{float:'left',width:'120px',height:'7vh',backgroundColor:'#0A253C',borderRadius:'12px'}} 
       
        >
      <b style={{fontSize:'12px'}}>Use Design</b>
    </button>
    <button className="smacid-btn" style={{float:'right',width:'120px',height:'7vh',backgroundColor:'white',borderRadius:'12px',boxShadow:'none',border:'2px solid #0A253C'}} 
    onClick={changeDesignPage}
    >
      <b style={{fontSize:'12px',color:'#0A253C'}}>Change Design</b>
    </button>
          {/* <button className="smacid-btn" onClick={goToClub} style={{ float: 'left', width: '110px', height: '50px', backgroundColor: 'white', borderRadius: '40px', marginTop: '20px', color: '#0A253C', border: '2px solid #0A253C' }}>
            <b style={{ fontSize: '18px' }}>Use Design</b>
          </button>
          <button className="smacid-btn" onClick={goToClub1} style={{ float: 'right', width: '100px', height: '50px', backgroundColor: 'white', borderRadius: '40px', marginTop: '20px', color: '#0A253C', border: '2px solid #0A253C' }}>
            <b style={{ fontSize: '18px' }}>Change Design</b>
          </button> */}
          </div>
          {/* <button className="smacid-btn" style={{ float: 'left', width: '383px', height: '64px', backgroundColor: '#0A253C', borderRadius: '40px', marginTop: '20px' }}>
            <span><b style={{ fontSize: '20px' }}>SHARE ON</b> <img src={fb} style={{ maxHeight: '37px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }} /><b style={{ fontSize: '31px' }}>/</b><img src={insta} style={{ maxHeight: '45px', maxWidth: '100px', marginLeft: '10px', marginBottom: '10px' }} /></span>
          </button> */}
          {/* <button className="smacid-btn" style={{ float: 'left', width: '350px', height: '64px', backgroundColor: 'white', borderRadius: '40px', marginTop: '20px', color: '#0A253C', border: '2px solid #0A253C' }}>
            <b style={{ fontSize: '18px' }}>JOIN THE CLUB!</b>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Club;
