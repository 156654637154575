import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Loader from "../loader/loader.js";
import "./choosewhisky.css";

const SearchingWhiskey = () => {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
      async function fetchData()
      {
        try {
          const response = await fetch('https://admin.smacflavourfolio.com/api/user/whiskeys_list');
          const data = await response.json();
          if(data!=null)
          {
          setApiData(JSON.parse(JSON.stringify(data)));
          const mdata=apiData.data[0];
          localStorage.setItem('whisky', JSON.stringify(mdata));
          setIsLoading(false);
          console.log(JSON.parse(localStorage.getItem('whisky')).whiskey_name);
          }
        } catch (error) {
          setIsLoading(false);
          console.error(error);
        }
      }
      fetchData();
  }, []);

  const handleClick= (param,param2,param3) =>
  {
    localStorage.setItem('whiskey_id', param);
    localStorage.setItem('whiskey_name', param2);
    localStorage.setItem('whisky_image',param3);
    console.log(param3);
    localStorage.setItem('is_on_the_nose','0');
    localStorage.setItem('is_on_the_palate','0');
    localStorage.setItem('finish','0');
    localStorage.setItem('completed_nose',null);
    localStorage.setItem('completed_palate',null);
    localStorage.setItem('on_the_palate',null);
    localStorage.getItem('selectedSubcategories',null);
    localStorage.setItem('on_the_nose',null);
    navigate("/Mainpage");
  }


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<div
        className="main"
        style={{ backgroundColor: "#fff0d9", height: "100vh", width: "100vw" }}
      >
        <div className="container mt-5 cw-header" style={{ paddingLeft:'10%',paddingRight:'10%'}}>
          <div className="row">
            <div className="col-9">
              <h4>
                <b>Choose the whisky that you want to evaluate</b>
              </h4>
            </div>
            <div className="col-3">
              <i
                className="fa-solid fa-magnifying-glass fa-2x"
                style={{ float: "right", color: "#c07f1e" }}
              onClick={()=>{navigate('/SearchingWhiskey')}} ></i>
            </div>
          </div>
        </div>
        <section className="container mt-5">
          <div className="row">
            {apiData["data"].map((data) => (
                 <div className="col-4" style={{ textAlign:'center' }} onClick={() => handleClick(data["id"],data["whiskey_name"],data["icon"]["url"])}>
                       <img className="whiskyBottle" src={data["icon"]["url"]} alt="" style={{ width:'60px' }}/>
                       <p>
                         <b>{data["whiskey_name"]}</b>
                       </p>
                     </div>
            ))}
            </div>
        </section>

        <div
          className="d-flex container justify-content-center text-align-center"
          style={{ width: "100vw", margin: "auto" }}
        >
          <div className="add-btn justify-content-center text-align-center" onClick={()=>{
         navigate("/addWhiskyForm");
        }}>
            <i
              className="fa-solid fa-plus fa-2x "
              style={{ marginTop: "10px", color: "#ffeea3" }}
            ></i>
            <p
              style={{
                width: "200px",
                margin: "25px",
                position: "relative",
                right: "95px",
              }}
            >
              <b>ADD WHISKY</b>
            </p>
          </div>
        </div>
      </div>
      )}
    </>
  );

};

export default SearchingWhiskey;