import React, { useState } from "react";
import "./smacid.css";
import bgimg from '../../assets/assest/Background.png';
import { useNavigate } from 'react-router-dom';
import Loader from "../loader/loader.js";
import Modal from 'react-modal';
import ReCAPTCHA from 'react-google-recaptcha';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useAuth } from "../Services/AuthContext";
import login_button from "../../assets/assest/login.png"

const Smacid = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const { login,logout } = useAuth();
  
  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      height: '200px',
    },
  };

  const [member_id, setSmacId] = useState("");
  const [pass, setPass] = useState("");

  async function fetchData() {
    if (captchaValue) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('member_detail', btoa(member_id));
      formData.append('password', btoa(pass));
      
      fetch('https://smacindia.com/Backend/api/verify_member', {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          if (data.errors) {
            setIsLoading(false);
            setCaptchaValue(null);
            setCaptchaError(false);
            handleOpenModal();
          } else {
            setCaptchaValue(null);
            setCaptchaError(false);
            localStorage.setItem('smacid', member_id);
            localStorage.setItem('member_id', data.data.id);
            localStorage.setItem('member_name', data.data.name);
            storeData();
          }
        });
    } else {
      setCaptchaError(true);
    }
  }

  async function storeData() {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('member_id', localStorage.getItem('member_id'));
    formData.append('name', localStorage.getItem('member_name'));
    formData.append('email', member_id);

    fetch('https://admin.smacflavourfolio.com/api/user/registerOrLogin', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        if (data.errors) {
          handleOpenModal();
        } else {
          localStorage.setItem('token', data.token);
          const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
          // const expirationTime = new Date().getTime() + 1 * 60 * 1000; // 1 minute from now
          localStorage.setItem('expirationTime', expirationTime);
          startSessionTimer(expirationTime);
          localStorage.removeItem('whiskey_id');
          localStorage.removeItem('on_the_nose');
          localStorage.removeItem('on_the_palate');
          localStorage.removeItem('on_the_nose_rating');
          localStorage.removeItem('on_the_palate_ratings');
          localStorage.removeItem('ratings');
          localStorage.removeItem('review');
          localStorage.removeItem('rating');
          localStorage.removeItem('reviee');
          login();
          const { gender } = data.data;

          if (gender) {
            navigate("/profiledashboard");
          } else {
            navigate("/editprofile");
          }
        }
          // navigate("/profiledashboard");
          
        // }
      });
  }

  const startSessionTimer = (expirationTime) => {
    const timeout = expirationTime - new Date().getTime();
    setTimeout(() => {
      logout();
    }, timeout);
  };

  const register = () => {
    window.open('https://smacindia.com/membership/');
  }

  const register1 = () => {
    handleCloseModal();
    window.open('https://smacindia.com/membership/');
  }

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="">
          {/* backgroundColor: "#ffffff", */}
          <div className="">
            <img src={bgimg} className="smac_img" alt="bg-img" />
          </div>
          <div>
            <div className="container banner my-2 mt-3">
              <div className="d-flex fw-bold mb-2" style={{ fontSize: '16px' }}>
                Welcome to Flavour Folio!
              </div>
              <p style={{ textAlign: 'justify', fontSize: '14px' }}>
                Discover the world’s first digital interface for recording tasting notes for spirits,
                powered by AI. With over 300 distinct aromas and flavors,
                our guided process will help you record your tastings like a pro.
                Log in or register now to start your flavorful journey.
              </p>
            </div>
          </div>
          <div className="container d-flex fw-bold" style={{ fontSize: '16px' }}>
            Log in
          </div>
          <div className="container d-flex" style={{ textAlign: 'left', fontSize: '14px' }} onClick={() => register()}>
          <p>SMAC ID, If you don't have one register today! <a href="https://smacindia.com/" target="_blank">Register now</a>  </p>
          </div>
          <div className="container">
          <div className="row">
            <div className="col-9">
          <div className=" justify-content-center align-items-center mt-2">
            <input
              className="form-control smac-input"
              placeholder=" Type SMAC ID  / Registered Email ID"
              onChange={event => setSmacId(event.target.value)}
            />
          </div>
         
          <div className="d-flex justify-content-center align-items-center mt-2" style={{ position: 'relative' }}>
            <input
              className="form-control smac-input"
              placeholder="Type your password here..."
              type={showPassword ? 'text' : 'password'}
              value={pass}
              onChange={event => setPass(event.target.value)}
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '18px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
            </div>
            </div>
            <div className="col-3 justify-content-center" style={{float:'right',marginTop:'33px'}} onClick={fetchData}>
                    {/* <img className="img-fluid back-logout" src={exit} alt="" /> */}
                    <button className="img-button gradient-border">
                      <img className="img-fluid back-logout gradient-border-img" src={login_button} style={{padding:'4px', width:'45px'}} alt="" />
                    </button>
                    {/* <p className="title-two"><b>LOG OUT</b></p> */}
                </div>
          </div>
         
          {/* <button onClick={fetchData} style={{border:'none',background:"none"}}>
            <img src={login_button} style={{marginLeft:'18px'}}></img>
           </button> */}
          </div>
          <div className="captcha">
            <ReCAPTCHA
              sitekey="6LeHw9spAAAAAEeAEVgYOhY8TH6m9CK2E_t1aj64"
              onChange={onCaptchaChange}
            />
            {captchaError && (
              <div style={{ color: 'red', marginTop: '5px' }}>
                Please complete the CAPTCHA
              </div>
            )}
          </div>
          <div className="container">
            {/* <button
              className="smacid-btn"
              style={{ float: 'left', width: '120px', backgroundColor: '#0A253C', textAlign: 'center', padding: '10px 0' }}
              onClick={() => register()}
            >
              <b style={{ fontSize: '12px', display: 'block', width: '100%' }}>
                Register
              </b>
            </button> */}

            {/* <button
              className="smacid-btn"
              style={{ float: 'right', backgroundColor: '#0A253C', textAlign: 'center', padding: '10px 0' }}
              onClick={fetchData}
            >
              <b style={{ fontSize: '12px', display: 'block', width: '100%' }}>
                Log in
              </b>
            </button> */}
          </div>

          <br />
          <br />
          {/* <div style={{ paddingRight: '5%', paddingLeft: '5%', width: '60%' }}>
            <span style={{ fontSize: '15px' }}>New Membership</span>
            <br />
            <br />
          </div> */}
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <p>The username or password you entered is incorrect, please verify & try again</p>
      </Modal>
    </div>
  );
};

export default Smacid;
