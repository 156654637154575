import React, { useEffect, useState } from "react"
import bgimg from '../../assets/assest/Background.png';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import fb from '../../assets/assest/fb.png';
import insta from '../../assets/assest/insta.png'
import "../club1/club1.css";
import Loader from "../loader/loader.js";
import backbutton from '../../assets/assest/back.jpeg'

const ProfileDetails = () => {
  const location = useLocation();
  const { report_id } = location.state || {};
  const [apiData,setApiData] = useState(null);
  const [isLoading, setIsLoading]= useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    async function fetchData()
    {
      try {
        const formData = new FormData();
        formData.append('report_id', report_id);
        const response = await fetch('https://admin.smacflavourfolio.com/api/user/reportDetail',{
        method:"POST",
        body:formData,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
      });
        const data = await response.json();
        if(data!=null)
        {
        console.log("data ",data);
        setApiData(data);
      //   const mdata=apiData.data[0];
      //   localStorage.setItem('whisky', JSON.stringify(mdata));
        setIsLoading(false);
         //console.log('data',apiData["data"]);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
    fetchData();
}, []);

const navigateToPage=()=>{
  navigate('/club',{state:{report_id:report_id}});
};
// const navigateToclub=()=>{
//   navigate('/club',{state:{report_id:report_id}});
// };
  const navigateToProfile= () => {
    window.history.back();
  };
  const convertRatingToStar = (rating) => {
    let stars = '';
    for (let i = 0; i < rating; i++) {
      stars += '★';
    }
    return stars;
  };
  const convertRatingToStars = (rating) => {
    const totalStars = 10;
    const filledStars = Math.round(rating);
    const starsArray = Array.from({ length: totalStars }, (v, i) => i < filledStars ? '★' : '☆');
    return starsArray.join('');
  };
    return (
      <div>
  {isLoading ? (
    <Loader />
  ) :
      <div className="" style={{fontFamily: 'Lato, sans-serif'}}>
         <div className="" >
  <div className="position-relative">
    <img
      src={bgimg}
      className="smac_img"
      alt="bg-img"
    />
    <div className="position-absolute top-0 start-0 m-3">
      <button
        type="button"
        className="btn-link"
        style={{ color: "white", border: 'none', background: 'transparent', cursor: 'pointer',marginTop:'20px' }}
        onClick={() => navigateToProfile()}
      >
        <img src={backbutton} style={{cursor:'pointer',width:'35px',height:'35px'}}/>
        {/* <i class="fa-solid fa-arrow-left" style={{ cursor: 'pointer' }}></i>{apiData?apiData.data.report.whisky.whiskey_name:''} */}
       
        {/* <i class="fa-solid fa-arrow-left"> style={{ cursor: 'pointer' }}></i>Amrut */}
      </button>
    </div>
  </div>
</div>
<div className="container my-2 mt-3">
          <p style={{textAlign:'justify',fontSize:'14px'}}>
          {apiData?apiData.data.report.description:''}
          </p>
          </div>
          <div className="review">
            <div className="reports">
            <div className="image">
             <img src={bgimg} alt="Image" style={{width:'96px',height:'84px'}}/>
             </div>
             <div className="content-container" style={{display: 'flex'}}>
              <div className="report-content" style={{width:'100px'}}>
            <span><h6 >On the Nose</h6>
            <p style={{fontSize:'12px'}}>
            {apiData?(apiData.data.onthenose.map((item)=>(
                <span>{item.name},</span>
              ))):''}
            </p>
            </span>
            </div>
            <div className="report-content" style={{ width: '121px' }}>
            {apiData && apiData.data.report.on_the_nose_rating !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '12px' }}>
                  {convertRatingToStars(apiData.data.report.on_the_nose_rating)}
                </span>
                <span style={{ color: 'black', fontSize: '12px' }}>
                  {apiData.data.report.on_the_nose_rating}.0
                </span>
              </p>
            )}
          </div>
          </div>
            </div>
            <div className="reports">
            <div className="image">
             <img src={bgimg} alt="Image" style={{width:'96px',height:'84px'}}/>
             </div>
             <div className="content-container" style={{display: 'flex'}}>
              <div className="report-content" style={{width:'100px'}}>
            <span><h6>On the Palate</h6>
            <p style={{fontSize:'12px'}}>
              {apiData?(apiData.data.onthepalate.map((item)=>(
                <span>{item.name},</span>
              ))):''}
            </p>
            </span>
            </div>
            <div className="report-content" style={{width:'121px',marginTop:'-4px'}}>
            {apiData && apiData.data.report.on_the_palate_ratings !== "null" && (
              <p>
                <span className="stars" style={{ fontSize: '12px' }}>
                  {convertRatingToStars(apiData.data.report.on_the_palate_ratings)}
                </span>
                <span style={{ color: 'black', fontSize: '12px' }}>
                  {apiData.data.report.on_the_palate_ratings}.0
                </span>
              </p>
            )}
          </div>
          </div>
            </div>
            <div className="reports" style={{gap:'5px'}}>
            <div className="image">
             <img src={bgimg} alt="Image" style={{width:'96px',height:'84px'}}/>
             </div>
             {/* <div className="content-container"> */}
              <div className="report-content" style={{width:'100px'}}>
            <span><h6 >Finish</h6>
           
                  {apiData && apiData.data.report.finish_option !== "null" && (
                    <p>
                      <span className="stars" style={{ fontSize: '12px' }}>
                        {convertRatingToStar(apiData.data.report.finish_option)}
                      </span>
                      <span style={{ color: 'black', fontSize: '12px' }}>
                        {apiData.data.report.finish_option}
                      </span>
                    </p>
                  )}
                    {/* <p>
                      {apidata.data.report.finish_option}
                    </p> */}
            
            {/* <p style={{fontSize:'12px'}}>{apiData?apiData.data.report.finish_option:''}</p> */}
            </span>
            </div>
            <div className="report-content" style={{width:'100px'}}>
             </div> 
            {/* </div> */}
            </div>
            <div style={{padding:'20px'}}>
           <button className="smacid-btn" style={{float:'left', width:'98%',height:'64px',backgroundColor:'#0A253C',borderRadius:'40px',marginTop:'-20px'}} onClick={navigateToPage}>
             <span><b style={{fontSize:'20px'}}>SHARE  ON</b> <img src={fb} style={{maxHeight:'40px',marginLeft:'10px',marginBottom:'10px'}}/><b style={{fontSize:'31px'}}>/</b><img src={insta} style={{maxHeight:'21px',maxWidth:'100px',marginLeft:'20px',marginBottom:'10px'}}/></span>
          </button>
          </div>
          </div>
          </div>
}
</div>
          )
          }
 export default ProfileDetails;