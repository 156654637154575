// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Glacial+Indifference&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whiskyBottle{
    width: 50%;
  }

body
{
  /* font-family: 'Glacial Indifference', sans-serif; */
  background-color: #FFFFFF;
  overflow-y:scroll;
}
/* 
@font-face {
  font-family: 'MyCustomFont';
  src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');
} */

.img-box
{
    padding: 10px;
}

.visibleClass
{
  display: block;
}

.notVisibleClass
{
  display: none;
}

.onThnose
{
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/OnTheNose/onthenose.css"],"names":[],"mappings":"AAEA;IACI,UAAU;EACZ;;AAEF;;EAEE,qDAAqD;EACrD,yBAAyB;EACzB,iBAAiB;AACnB;AACA;;;;GAIG;;AAEH;;IAEI,aAAa;AACjB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,eAAe;EACf,UAAU;EACV,QAAQ;EACR,WAAW;AACb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Glacial+Indifference&display=swap');\n\n.whiskyBottle{\n    width: 50%;\n  }\n\nbody\n{\n  /* font-family: 'Glacial Indifference', sans-serif; */\n  background-color: #FFFFFF;\n  overflow-y:scroll;\n}\n/* \n@font-face {\n  font-family: 'MyCustomFont';\n  src: url('../../fonts/Tahoma Regular font.ttf') format('truetype');\n} */\n\n.img-box\n{\n    padding: 10px;\n}\n\n.visibleClass\n{\n  display: block;\n}\n\n.notVisibleClass\n{\n  display: none;\n}\n\n.onThnose\n{\n  position: fixed;\n  bottom: 0%;\n  left: 0%;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
