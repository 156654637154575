import React, { useState, useEffect } from "react";
import './onthenose.css';
import '../Finishonthewhisky/Finishonthewhisky.css';
import { FaSearch, FaTimes } from 'react-icons/fa';
import Loader from "../loader/loader.js";
import mask from "../../assets/assest/Mask Group 1.png";
import backbutton from '../../assets/assest/back.jpeg'
import { useNavigate} from 'react-router-dom';
import Modal from 'react-modal';

const OnTheNose = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [query, setQuery] = useState('');
  const [category,setCategory] = useState(false);
  const [searchData, setSearchData] =useState(null);

  const closeModal = () => {
    setModalOpen(false);
  };
  const openModal = () => {
    setModalOpen(true);
  };
  const handleClearInput = () => {
    setQuery('');
    setSearchData(apiData["data"]); 
  };
  useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
  async function fetchData() {
    const formData = new FormData();
    formData.append('type', localStorage.getItem('type'));
      setTimeout(() => {
        fetch('https://admin.smacflavourfolio.com/api/user/categories', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setApiData(JSON.parse(JSON.stringify(data)));
        setSearchData(JSON.parse(JSON.stringify(data))["data"]);
        setIsLoading(false);
      });
      }, 2000);
    }
    fetchData();
  }, []);

  const selectCategory = (id,name) =>
  {
    localStorage.setItem('nose_category',id);
    localStorage.setItem('name',name);
    console.log('nose_category',id);
    navigate('/OnTheNosePlate');
  }

 const checkCategory = (params) => 
 {
  if(localStorage.getItem('isActive')=='0')
  {
    if(localStorage.getItem('completed_nose')==null)
    {
      localStorage.setItem('completed_nose','[]');
    }
    const storedArray=JSON.parse(localStorage.getItem('completed_nose'));
    if(storedArray==null)
    {
      return '0';
    }
    console.log('my array',storedArray);
    if(storedArray.includes(params.toString()))
    {
      if(!category)
      {
      setCategory(true);
      }
      return '1';
    }
    else
    {
      return '0';
    }
  }
  else
  {
    
    if(localStorage.getItem('completed_palate')==null)
    {
      localStorage.setItem('completed_palate','[]');
      // setCategory(JSON.parse(storedArray));
    }
    const storedArray=JSON.parse(localStorage.getItem('completed_palate'));
    console.log('my array1',storedArray);
    if(storedArray==null)
    {
      return '0';
    }
    if(storedArray.includes(params.toString()))
    {
      if(!category)
      {
      setCategory(true);
      }
      return '1';
    }
    else
    {
      return '0';
    }
  }

 }

  const handleGoBack = () => {
    openModal();
    // window.history.back();
  };
  const handleBack = () => {
    window.history.back();
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setQuery(value);
    if(value.length>0)
    {
      setIsLoading1(true);
      const formData = new FormData();
      formData.append('keyword', value);
      formData.append('type', localStorage.getItem('type'));
      fetch('https://admin.smacflavourfolio.com/api/user/search', {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          setSearchData(JSON.parse(JSON.stringify(data))["data"]);
          setIsLoading1(false);
          // if(apiData["errors"])
          // {
          //    console.log('result',apiData["errors"]);
          // }
          // else
          // {
          //   console.log('result',apiData["errors"]);
          //   navigate("/OnTheNosePlate");
          // }
        });
    const filteredItems = apiData["data"].filter((item) =>
    item["name"].toLowerCase().includes(value.toLowerCase())
    );
    console.log('search data',filteredItems);
     setSearchData(filteredItems);
    }
    else
    {
      setSearchData(apiData["data"]); 
    }
  };


  return (
    <div className="dashboardn-background">
    {isLoading ? (
        <Loader />
      ) : (<div
        class="p-2">
        <div className="container cw-header">
          <div className="row" style={{ paddingBottom:'20px' }}>
             <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer',marginTop:'4px',marginLeft:'-10px' }}
                 onClick={()=>handleGoBack()}
             
                >
                <div className='gradient-border'>
                  <img className="gradient-border-img" src={backbutton} style={{cursor:'pointer',width:'50px',height:'50px',borderRadius:'13px'}}/>
                </div>
              </button>
            </div>
            {/* <div className="col-3">
              <i
                className="fa-solid fa-magnifying-glass fa-2x"
                style={{ float: "right", color: "#c07f1e",fontSize:'24px' }}
                onClick={()=>{navigate('/Search');}}
              ></i>
            </div> */}
            <h5 className="d-flex justify-content-center align-items-center">
            <strong>  {localStorage.getItem('isActive')=="0"?'AROMATICS':'ON THE PALATE'}</strong>
           {/* <strong>WELCOME {localStorage.getItem('member_name')}</strong> */}
          </h5>
            {/* <div className='col-12 d-flex justify-content-center mt-4'>
               <b style={{fontSize:'18px'}}> {localStorage.getItem('isActive')=="0"?'AROMATICS':'ON THE PALATE'}</b>
            </div> */}
          </div>
          <div className="row">
          <div className="col-12">
          <div style={{ position: 'relative', width: '100%' }}>
      <input
        type="text"
        placeholder="Search..."
        className="design_search"
        value={query}
        onChange={handleInputChange}
        style={{ width: '100%', height: '40px', paddingRight: '35px', paddingLeft: '5px' }}
      />
      {query && (
        <div
        style={{
          position: 'absolute',
          top: '45%',
          right: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex:'9999',
          color: '#000', // Change the color of the clear icon as needed
        }}
        onClick={handleClearInput}
      >
        <FaTimes />
      </div>
      )}
      {/* <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          color: '#999', // Change the color of the search icon as needed
        }}
      >
        <FaSearch />
      </div> */}
    </div>
            </div>
            {/* <div className='col-12 d-flex justify-content-center mt-4'>
               <b style={{fontSize:'18px'}}> ON THE NOSE</b>
            </div> */}
          </div>
        </div>

        {isLoading1 ? (
        <Loader />
      ) : (<section className="mt-1">
          <br />
          <div className="row" style={{paddingRight:'5%',paddingLeft:'5%'}}>
            {searchData.map((data) => (
            // <div className="col-6 d-flex justify-content-center img-box" onClick={()=>selectCategory(data["id"],data["name"])}>
            //         <h4 style={{textAlign:'center'}}>
            //         <img className="whiskyBottle" src={`${data['icon']==null ? img1 : data['icon']['url']}`} alt="" /><br/><br/>
            //         <p style={{fontSize:'14px'}}>{data["name"]}</p>
            //         <div className={`${checkCategory(data["id"])=='1' ? 'visibleClass' : 'notVisibleClass'}`}>
            //       <div className={`d-flex  justify-content-center  align-items-center`}>
            //           <div className={`mbox1`} style={{float:'right'}}>
            //                <i class="fa-solid fa-check"></i>
            //           </div>&nbsp;
            //         <span style={{color:'#327D01',fontSize:'12px',paddingTop:'3px'}}>Completed</span>
            //     </div>
            //   </div>
            //         </h4>

              <div class="col-12 col-lg-12 col-md-12 col-sm-12" style={{cursor:'pointer',width:'100%'}} onClick={()=>selectCategory(data["id"],data["name"])}>
              <div class="outerbox mb-3 justify-content-between"
              style={{boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',background:'#F4F6FC'}}
              >
              <div style={{textAlign:'center',width:'100%'}}>
              <img className="whiskyBottle" src={`${data['icon']==null ? mask : data['icon']['url']}`} alt="" style={{ width:'100%',padding:'5px',height:'140px' }}/>
              <div class="container" style={{padding:'0px',margin:'0px',justifyContent: 'space-between'}}>
                <div className="row">
                <div className={`${checkCategory(data["id"])=='1' ? 'col-8' : 'col-12'}`}>
                  <span style={{fontSize:'13px',fontWeight:'bold',textAlign:'left',display: 'inline-block',whiteSpace: 'nowrap',overflow:'hidden',textOverflow: 'ellipsis',paddingLeft:'2px',maxWidth:'100%'}}>{data["name"]}</span>
                </div>
                <div className={`${checkCategory(data["id"])=='1' ? 'col-4 visibleClass' : 'notVisibleClass'}`} style={{paddingRight:'20px',paddingBottom:'4px'}}>
                <div className={`box1`} style={{background:'green',float:'right'}}>
                  <i class="fa-solid fa-check"></i>
                </div>
                </div>
                </div>
                </div>
                </div>
              </div>
            </div>

            // </div>            

            ))}
            {/* <div className="col-6 d-flex justify-content-center img-box">
              <h4 style={{textAlign:'center'}}>
              <img className="whiskyBottle" src={img2} alt="" /><br/><br/>
              <p style={{fontSize:'14px'}}>Aromatic & flowery?*</p>
              </h4>
            </div>
            <div className="col-6 d-flex justify-content-center img-box">
              <h4 style={{textAlign:'center'}}>
              <img className="whiskyBottle" src={img3} alt="" /><br/><br/>
              <p style={{fontSize:'14px'}}>Aromatic & flowery?*</p>
              </h4>
            </div>
            <div className="col-6 d-flex justify-content-center img-box">
              <h4 style={{textAlign:'center'}}>
              <img className="whiskyBottle" src={img4} alt="" /><br/><br/>
              <p style={{fontSize:'14px'}}>Aromatic & flowery?*</p>
              </h4>
            </div>
            <div className="col-6 d-flex justify-content-center img-box">
              <h4 style={{textAlign:'center'}}>
              <img className="whiskyBottle" src={img5} alt="" /><br/><br/>
              <p style={{fontSize:'14px'}}>Aromatic & flowery?*</p>
              </h4>
            </div>
            <div className="col-6 d-flex justify-content-center img-box">
              <h4 style={{textAlign:'center'}}>
              <img className="whiskyBottle" src={img5} alt="" /><br/><br/>
              <p style={{fontSize:'14px'}}>Aromatic & flowery?*</p>
              </h4>
            </div>
            <div className="col-6 d-flex justify-content-center img-box">
              <h4 style={{textAlign:'center'}}>
              <img className="whiskyBottle" src={img6} alt="" /><br/><br/>
              <p style={{fontSize:'14px'}}>Aromatic & flowery?*</p>
              </h4>
            </div> */}
          </div>
        </section>
        )}
                <div className="d-flex container justify-content-center text-align-center">

        <div className={`${category ? 'visibleClass' : 'notVisibleClass'}`}>
        <button class="fixed-button" onClick={() => {navigate('/rating')}}style={{backgroundColor:'#0A253C',border:'2px solid #0A253C55i'}}>SUBMIT</button>
    </div>
        </div>
      </div>
      )}
     <Modal
  isOpen={modalOpen}
  onRequestClose={closeModal}
  contentLabel="Example Modal"
  style={{
    content: {
      position: 'absolute',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-48%, -50%)',
              border: 'none',
              borderRadius: '10px',
              padding: '20px',
              maxWidth: '330px',
              width: '74%',
              backgroundColor: '#f9f9f9',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
      
    }
  }}
>
  <div>
    <p className="font">Please submit your feedback by clicking the submit button...</p>
    <button style={{ float: 'right', backgroundColor: '#0A253C' }} className="btn btn-success" onClick={() => navigate('/rating')}>Submit</button>
    <button style={{ float: 'left', backgroundColor: '#0A253C' }} className="btn btn-primary" onClick={() =>handleBack()}>Go back</button>
  </div>
</Modal>

    </div>
  )
}

export default OnTheNose