import React, { useEffect, useState, useCallback } from 'react';
import "./editprofile.css";
import { useNavigate } from 'react-router-dom';
import bgimg from '../../assets/assest/Background.png';
import profilePlaceholder from '../../assets/assest/profile icon.png';
import edit from '../../assets/assest/pencil.jpeg';
import Loader from '../loader/loader';
import { useAuth } from '../Services/AuthContext';
import backbutton from '../../assets/assest/back.jpeg';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import { useDropzone } from 'react-dropzone';
import imageCompression from "browser-image-compression";

// import Dropzone from 'react-dropzone';
import { getCroppedImg } from './cropImage';
import { v4 as uuidv4 } from 'uuid';

const EditProfile = () => {
  const navigate = useNavigate();
  const { logout, fetchProfile, profileData } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [profileImage, setProfileImage] = useState(profilePlaceholder);
  const [gender, setGender] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCropEnable,setIsCropEnable] = useState(false);
  

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };
  

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      try {
        await fetchProfile();
        if (profileData && profileData.name) {
          setName(profileData.name);
          setGender(profileData.gender || '');
          setProfileImage(profileData.profile ? profileData.profile.url : profilePlaceholder);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Failed to fetch profile data', error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, [fetchProfile, profileData]);


  const getFileExtension = (fileName) => {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  };

  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
  };

  const onFileChange = event => {
    const file = event.target.files[0];
    handleImageUpload(file);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

const updateProfile = async () => {
  // const nameChanged = name.trim() !== (profileData ? profileData.name.trim() : '');
  // const fileChanged = file !== null;
  // const genderChanged =
  // gender !== '' &&
  // (profileData ? profileData.gender !== gender : false);
  // console.log('Name Changed:', nameChanged);
  // console.log('File Changed:', fileChanged);
  // console.log('Gender Changed:', genderChanged);
  const isProfileNew = !profileData;

  if (isProfileNew && !gender) { 
    setError('Gender selection is mandatory for new users.');
    return;
  }
  
  // if (genderChanged) {
  //   console.log('Selected Gender:', gender); 
  // }
  // if (!gender && !profileData) { 
  //   setError('Gender selection is mandatory for new users.');
  //   return;
  // }

  
  // if (!nameChanged && !fileChanged && !genderChanged) {
  //   setError('No changes made. You must change the name, gender, or add a profile picture before updating.');
  //   return;
  // }

  setError('');
  setIsLoading(true);
  try {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('gender', gender);
    if (file) {
      formData.append('profile', file);
    }
    const response = await fetch('https://admin.smacflavourfolio.com/api/user/updateProfile', {
      method: "POST",
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    setError('');
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('gender', gender);
      if (croppedImage) {
        formData.append('profile', file);
      }
      const response = await fetch('https://admin.smacflavourfolio.com/api/user/updateProfile', {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // if (data) {
      //   console.log("updated data ", data);
      //   await fetchProfile();
      //   setIsLoading(false);
      //   navigate("/profileDashboard");
      if (data != null) {
        console.log("updated data ", data);
        await fetchProfile();
        window.location.href = "/profileDashboard";
      } else {
        setIsLoading(false);
        setError('An error occurred while updating the profile. Please try again later.');
      }
    
    } catch (error) {
      setIsLoading(false);
      setError('An error occurred while updating the profile. Please try again later.');
    }
  
  } catch (error) {
    setIsLoading(false);
    setError('An error occurred while updating the profile. Please try again later.');
    console.error(error);
  }
};

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handlePopstate = () => {
    window.history.back();
  };


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // const onDrop = useCallback(acceptedFiles => {
  //   const file = acceptedFiles[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     setImageSrc(reader.result);
  //   };
  // }, []);

  const customStyles = {
    content: {
      top: '5%',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      width: '100%',
      padding: '20px',
      border: 'none',
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#fff'
    }
  };

  const checkfile=(file)=> {
    const formData = new FormData();
    formData.append('file', file);
    fetch('https://admin.smacflavourfolio.com/api/user/nudityDetection', {
      method: 'POST',
      body: formData
    })
    .then(response => response.json())
      .then(data => {
      // Handle the response
      if(data["errors"]==false)
        {
          setFile(file);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
          setImageSrc(reader.result);
          handleOpenModal();
          setIsCropEnable(true);
          setErrorMessage("");
    };
        }
        else
        {
          setFile(null);
          setImageSrc(null);
          setErrorMessage(data["message"]);
        }
    })
    .catch(error => {
      // Handle the error
    });
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const croppedUrl = URL.createObjectURL(croppedBlob);
      setCroppedImage(croppedUrl);
      setFile(croppedBlob);
      setIsCropEnable(false);
      handleCloseModal();
      
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);
  const cancelCrop = () => {
    // Logic to cancel the cropping process
    setCroppedAreaPixels(null); // Reset the cropped area
    setIsCropEnable(false);     // Disable cropping
    handleCloseModal();         // Close the modal if needed
    console.log("Cropping process cancelled");
  };

  const handleImageUpload = async (imageFile) => {
    // Options for compression
    const options = {
      maxSizeMB: 2, // (2 MB)
      maxWidthOrHeight: 1024, // (You can set a maximum width/height)
      useWebWorker: true, // optional, defaults to true
    };

    try {
      const compressedImage = await imageCompression(imageFile, options);
      checkfile(compressedImage);
      
      // You can now handle the compressed image
      // console.log("Original File Size:", imageFile.size / 1024, "KB");
      // console.log("Compressed File Size:", compressedImage);

      // // Example: create URL for the compressed image for preview or upload
      // const compressedImageUrl = URL.createObjectURL(compressedImage);
      // console.log("Compressed Image URL:", compressedImageUrl);
    } catch (error) {
      console.error("Error during image compression:", error);
    }
  };

  return (
    <div className="dashboardn-background">
      <div>
              <button
                type="button"
                className="btn-link"
                style={{ color: "black",border:'none',background:'transparent',cursor:'pointer',marginTop:'15px',marginLeft:'10px'}}
                       onClick={ handlePopstate}>
                 
             
                
                <div className='gradient-border'>
                  <img className="gradient-border-img" src={backbutton} style={{cursor:'pointer',width:'50px',height:'50px',borderRadius:'13px'}}/>
                </div>
              </button>
            </div>
    <div className='container'>
      <div className="user-profile">
        <div className="profile-header">
          <div className="profile-details">
            <div className="profile-container">

      {/* <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop an image here, or click to select one</p>
          </div>
        )}
      </Dropzone> */}
              <img
                src={croppedImage ? croppedImage : profileImage}
                className="profile-img"
                alt="profile-img"
                style={{ marginTop:'147px' }}
              />
              <img
                src={edit}
                className="edit-icon1"
                alt="edit"
                onClick={triggerFileInput}
              />
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={onFileChange}
              />
              <p style={{color:"red",marginTop: '-170px' }}>{errorMessage}</p>
              <p style={{ marginTop: '50px' }}>{profileData ? profileData.member_id : ''}</p>
              <div className="justify-content-center align-items-center mt-2 mb-3">
                <input
                  className="form-control smac-input"
                  placeholder="Type your Name."
                  value={name}
                  onChange={handleNameChange}
                />
                <select value={gender} onChange={handleGenderChange} className="form-control smac-input mt-3">
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <button className='update-btn' onClick={updateProfile}>Update</button>
              {error && <p style={{ color: 'red', padding: '10px' }}>{error}</p>}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Crop Image"
        style={customStyles}
      >
       {isCropEnable && (
        <div>
          <div className="crop-container">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="controls">
          <button onClick={cancelCrop} className='smacid-btn' style={{backgroundColor: '#0A253C',width: '40%', float:'left',height: '7vh'}}>Cancel</button>
            <button onClick={showCroppedImage} className='smacid-btn' style={{backgroundColor: '#0A253C',width: '40%', float:'right',height: '7vh'}}>Crop</button>
          </div>
        </div>

      )}
      </Modal>
    </div>
    </div>
  );
};

export default EditProfile;


// import React, { useEffect, useState } from 'react';
// import "./editprofile.css";
// import { useNavigate } from 'react-router-dom';
// import profilePlaceholder from '../../assets/assest/profile icon.png';
// import edit from '../../assets/assest/pencil.jpeg';
// import { useAuth } from '../Services/AuthContext';
// import backbutton from '../../assets/assest/back.jpeg';
// import ImageCropper from './ImageCropper'; // Import ImageCropper component

// const EditProfile = () => {
//   const navigate = useNavigate();
//   const { fetchProfile, profileData } = useAuth();
//   const [isLoading, setIsLoading] = useState(false);
//   const [name, setName] = useState('');
//   const [error, setError] = useState('');
//   const [file, setFile] = useState(null);
//   const [previewImage, setPreviewImage] = useState(null);
//   const [profileImage, setProfileImage] = useState(profilePlaceholder);
//   const [gender, setGender] = useState('');
//   const [errorMessage, setErrorMessage] = useState('Size Limit:10MB');
//   const [cropMode, setCropMode] = useState(false); // State to toggle crop mode

//   useEffect(() => {
//     setIsLoading(true);
//     async function fetchData() {
//       try {
//         await fetchProfile();
//         if (profileData && profileData.name) {
//           setName(profileData.name);
//           setGender(profileData.gender || '');
//           setProfileImage(profileData.profile ? profileData.profile.url : profilePlaceholder);
//           setIsLoading(false);
//         }
//       } catch (error) {
//         console.error('Failed to fetch profile data', error);
//         setIsLoading(false);
//       }
//     }
//     fetchData();
//   }, [fetchProfile, profileData]);

//   function handleFileChange(event) {
//     const file = event.target.files[0];

//     if (file) {
//       const fileSizeInBytes = file.size;
//       const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

//       if (fileSizeInBytes <= maxSizeInBytes) {
//         const allowedExtensions = ['jpg', 'jpeg', 'png'];
//         const fileExtension = getFileExtension(file.name);
//         if (allowedExtensions.includes(fileExtension.toLowerCase())) {
//           setFile(file);
//           setPreviewImage(URL.createObjectURL(file));
//           setCropMode(true); // Enable crop mode
//         } else {
//           setFile(null);
//           setPreviewImage(null);
//           setErrorMessage('Invalid file extension. Only JPG, JPEG, and PNG files are allowed.');
//         }
//       } else {
//         setFile(null);
//         setPreviewImage(null);
//         setErrorMessage('File size exceeds the maximum limit (10MB).');
//       }
//     } else {
//       setFile(null);
//       setPreviewImage(null);
//       setErrorMessage('Size Limit:10MB');
//     }
//   }

//   const getFileExtension = (fileName) => {
//     const parts = fileName.split('.');
//     return parts[parts.length - 1];
//   };

//   const triggerFileInput = () => {
//     document.getElementById('fileInput').click();
//   };

//   const handleNameChange = (event) => {
//     setName(event.target.value);
//   };

//   const handleCropComplete = (croppedImage) => {
//     setProfileImage(croppedImage);
//     setCropMode(false); // Disable crop mode
//   };

//   const handleCancelCrop = () => {
//     setFile(null);
//     setPreviewImage(null);
//     setCropMode(false); // Disable crop mode
//   };

//   const updateProfile = async () => {
//     const isProfileNew = !profileData;

//     if (isProfileNew && !gender) { 
//       setError('Gender selection is mandatory for new users.');
//       return;
//     }

//     setError('');
//     setIsLoading(true);
//     try {
//       const formData = new FormData();
//       formData.append('name', name);
//       formData.append('gender', gender);
//       if (file) {
//         formData.append('profile', file);
//       }
//       const response = await fetch('https://admin.smacflavourfolio.com/api/user/updateProfile', {
//         method: "POST",
//         body: formData,
//         headers: {
//           'Authorization': 'Bearer ' + localStorage.getItem('token'),
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const data = await response.json();
//       if (data != null) {
//         console.log("updated data ", data);
//         await fetchProfile();
//         window.location.href = "/profileDashboard";
//       } else {
//         setIsLoading(false);
//         setError('An error occurred while updating the profile. Please try again later.');
//       }
    
//     } catch (error) {
//       setIsLoading(false);
//       setError('An error occurred while updating the profile. Please try again later.');
//       console.error(error);
//     }
//   };

//   const handleGenderChange = (event) => {
//     setGender(event.target.value);
//   };

//   const handlePopstate = () => {
//     window.history.back();
//   };

//   return (
//     <div className="dashboardn-background">
//       {cropMode ? (
//         <ImageCropper 
//           imageSrc={previewImage}
//           onCropComplete={handleCropComplete}
//           onCancel={handleCancelCrop}
//         />
//       ) : (
//         <div className="">
//           <button type="button" className="btn-link" style={{ color: "white", border: 'none', background: 'transparent', cursor: 'pointer' }}
//               onClick={ handlePopstate}>
//             <img src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px', marginLeft: '20px',marginTop:'22px',borderRadius:'13px' }} />
//           </button>
//           <div className="user-profile">
//             <div className="profile-header">
//               <div className="profile-details">
//                 <div className="profile-container">
//                   <img
//                     src={profileImage}
//                     className="profile-img"
//                     alt="profile-img"
//                     style={{ marginTop:'147px' }}
//                   />
//                   <img
//                     src={edit}
//                     className="edit-icon1"
//                     alt="edit"
//                     onClick={triggerFileInput}
//                   />
//                   <input
//                     id="fileInput"
//                     type="file"
//                     accept="image/*"
//                     style={{ display: 'none' }}
//                     onChange={handleFileChange}
//                   />
//                   <p style={{ marginTop: '-127px' }}>{profileData ? profileData.member_id : ''}</p>
//                   <div className="justify-content-center align-items-center mt-2 mb-3">
//                     <input
//                       className="form-control smac-input"
//                       placeholder="Type your Name."
//                       value={name}
//                       onChange={handleNameChange}
//                     />
//                     <select value={gender} onChange={handleGenderChange} className="form-control smac-input mt-3">
//                       <option value="">Select Gender</option>
//                       <option value="male">Male</option>
//                       <option value="female">Female</option>
//                     </select>
//                   </div>
//                   <button className='update-btn' onClick={updateProfile}>Update</button>
//                   {error && <p style={{ color: 'red', padding: '10px' }}>{error}</p>}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default EditProfile;


