import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import whisky from "../../assets/assest/Background.png";
import bottle from "../../assets/assest/1.gif";
import Loader from "../loader/loader.js";
import background from '../../assets/assest/background-image.png';
import logo from "../../assets/assest/logo.png"
// import logoa from "../../assets/assest/logo.png"

const Dashboard = () => {
  const navigate = useNavigate();
  const [apiData, setApiData] = useState(null);
  const [searchData, setSearchData] =useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    async function fetchData() {
    setTimeout(() => {
      fetch('https://admin.smacflavourfolio.com/api/user/banner', {
      method: 'GET',
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setApiData(JSON.parse(JSON.stringify(data)));
      if(JSON.parse(JSON.stringify(data))["data"]!=null)
      {
        if(JSON.parse(JSON.stringify(data))["data"].length>0)
        {
          setSearchData(JSON.parse(JSON.stringify(data))["data"]);
        }
      }
      setIsLoading(false);
      });
     }, 2000);
    }

  fetchData();
}, []);

  const clickNavigate = () =>{
      localStorage.setItem('whiskey_id',null);
      localStorage.setItem('on_the_nose',null);
      localStorage.setItem('on_the_palate',null);
      localStorage.setItem('on_the_nose_rating',null);
      localStorage.setItem('on_the_palate_ratings',null);
      localStorage.setItem('ratings',null);
      localStorage.setItem('review',null);
      localStorage.setItem('rating',null);
      localStorage.setItem('reviee',null);
      navigate("/smacid");
  }

  
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
      <div className="flavour-folio-container">
        <img src={background} alt="Background" className="background-image" />
        <div className="overlay">
          <div className="row ">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <div className="logo-container">
                <img src={logo} alt="Flavour Folio Logo" className="logo img-fluid" />
              </div>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
                  <button className="start-button" onClick={clickNavigate}>
                  START
                  <i class="fa-solid fa-arrow-right-long p-2"></i>
                  </button>
                  
         
              {/* <button className="start-button" onClick={clickNavigate}>
                START
              </button> */}
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default Dashboard;
