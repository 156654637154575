// import React from 'react';
// import './reward.css';
// import { useNavigate } from 'react-router-dom';
// import whisky from '../../assets/assest/whisky.jpg'
// import blackdoglogo from '../../assets/assest/black-dog.png'
// import backbutton from '../../assets/assest/back.jpeg';
// import smaclogo from '../../assets/assest/default.png'

// const RewardPage = () => {
//     const rewards = [
//         { id: 1, title: 'Flat ₹200 off', brand: 'BlackDog', description: 'on points above 200', liked: '47k liked', image: whisky, logo: blackdoglogo },
//         { id: 2, title: 'Flat ₹200 off', brand: 'BlackDog', description: 'on points above 200', liked: '47k liked', image: whisky, logo: blackdoglogo },
//         { id: 3, title: 'Flat ₹200 off', brand: 'BlackDog', description: 'on points above 200', liked: '47k liked', image: whisky, logo: blackdoglogo },
//         { id: 4, title: 'Flat ₹200 off', brand: 'BlackDog', description: 'on points above 200', liked: '47k liked', image: whisky, logo: blackdoglogo },
//         { id: 5, title: 'Flat ₹200 off', brand: 'BlackDog', description: 'on points above 200', liked: '47k liked', image: whisky, logo: blackdoglogo },
//       ];
      
//   const navigate = useNavigate();
//   const goToRewardDetails = (id) => {
//     navigate(`/reward/${id}`);
//   };
//   const handlePopstate = () => {
//     window.history.back();
//   };

//   return (
//     <div className='container'>
//     <div className="reward-container">
      
//       <div className='row'>
//     <div className="position-absolute mt-2 mb-5">
//     <button
//       type="button"
//       className="btn-link"
//       style={{
//         color: 'white',
//         border: 'none',
//         background: 'transparent',
//         cursor: 'pointer',
        
//       }}
//       onClick={handlePopstate}
//     >
//       <div className='gradient-border'>
//         <img src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px',borderRadius:'13px' }} alt="Back" />
//         </div>
//     </button>
//   </div>
  
//   <div style={{ position: 'relative' }}>
//   <div style={{ position: 'absolute', right: '10px', top: '20px' }}>
//   <button className="get-code-btn">3000 Points</button>
//   </div>
// </div>

//   </div>
//     <div className="reward-page">
//       {/* <div className="reward-summary">

//       </div> */}
//       <h2>Vouchers</h2>
//       <div className="reward-grid">
//         {rewards.map((reward, index) => (
//          <div key={reward.id} className="reward-card" onClick={() => goToRewardDetails(reward.id)}>
//             <div className="reward-image">
//               <img src={reward.image} alt={reward.title} />
//             </div>
//             <div className="reward-logo">
//               <img src={reward.logo} alt={`${reward.brand} logo`} />
//             </div>
//             <div className="reward-content">
//               <div className="reward-header">
//                 <h3>{reward.title}</h3>
//               </div>
//               <p className="reward-brand">{reward.brand}</p>
//               <p className="reward-description">{reward.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
   
//     </div>
//     </div>
//   );
// };

// export default RewardPage;

import React, { useEffect, useState } from 'react';
import './reward.css';
import { useNavigate } from 'react-router-dom';
import whisky from '../../assets/assest/whisky.jpg'
import blackdoglogo from '../../assets/assest/black-dog.png'
import backbutton from '../../assets/assest/back.jpeg';
import logo from '../../assets/assest/default.png'

const RewardPage = () => {
  const [rewards, setRewards] = useState([]); 
  const [isLoading, setIsLoading] = useState(true); 
  const navigate = useNavigate();
  const fetchRewards = async () => {
    try {
      const token = '541|u3brXByBatbjpNUv0VNMHy4RTvAJTBJKBL8yQeph994220bd'; 
      //  const token = localStorage.getItem('token'); 

      const response = await fetch('http://127.0.0.1:8000/api/user/getVoucherPoints', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch rewards');
      }
  
      const data = await response.json();
      console.log("Full API Response:", data);
  
      
      if (data && Array.isArray(data.data)) {
        setRewards(data.data); 
        console.log('Setting rewards:', data.data); 
      } else {
        console.error('Unexpected data format:', data);
        setRewards([]); 
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching rewards:', error);
      setIsLoading(false);
    }
  };
  
 
  
  useEffect(() => {
    fetchRewards(); 
  }, []);

  const goToRewardDetails = (id) => {
    navigate(`/reward/${id}`);
  };

  const handlePopstate = () => {
    window.history.back();
  };

  return (
    <div className='container'>
      <div className="reward-container">
        <div className='row'>
          <div className="position-absolute mt-2 mb-5">
            <button
              type="button"
              className="btn-link"
              style={{
                color: 'white',
                border: 'none',
                background: 'transparent',
                cursor: 'pointer',
              }}
              onClick={handlePopstate}
            >
              <div className='gradient-border'>
                <img src={backbutton} style={{ cursor: 'pointer', width: '50px', height: '50px', borderRadius: '13px' }} alt="Back" />
              </div>
            </button>
          </div>

          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', right: '10px', top: '20px' }}>
              <button className="get-code-btn">3000 Points</button>
            </div>
          </div>
        </div>

        <div className="reward-page">
          <h2>Vouchers</h2>
          {isLoading ? ( 
            <p>Loading rewards...</p>
          ) : (
            <div className="reward-grid">
  {rewards.length === 0 ? (
  <p>No rewards available</p> 
) : (
  rewards.map((reward) => (
    <div key={reward.id} className="reward-card" onClick={() => goToRewardDetails(reward.id)}>
      <div className="reward-image">
        <img src={reward.images && reward.images.length > 0 ? reward.images[0].url : whisky} alt={reward.title} /> 
      </div>
      <div className="reward-logo">
        <img src={logo} alt={`${reward.title} logo`} /> 
      </div>
      <div className="reward-content">
        <div className="reward-header">
          <h3>{reward.title}</h3>
        </div>
        {/* Displaying points before category */}
        <p className="reward-brand">{reward.points} points <br></br>{reward.category}</p> 
        <p className="reward-description">{reward.description}</p>
      </div>
    </div>
  ))
)}

</div>

            // <div className="reward-grid">
            //   {rewards.map((reward) => (
            //     <div key={reward.id} className="reward-card" onClick={() => goToRewardDetails(reward.id)}>
            //       <div className="reward-image">
            //         <img src={reward.image || whisky} alt={reward.title} /> 
            //       </div>
            //       <div className="reward-logo">
            //         <img src={reward.logo || blackdoglogo} alt={`${reward.brand} logo`} /> {/* Fallback logo if not available */}
            //       </div>
            //       <div className="reward-content">
            //         <div className="reward-header">
            //           <h3>{reward.title}</h3>
            //         </div>
            //         <p className="reward-brand">{reward.brand}</p>
            //         <p className="reward-description">{reward.description}</p>
            //       </div>
            //     </div>
            //   ))}
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RewardPage;
